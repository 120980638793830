import { Link, useParams } from "react-router-dom";
import Nav2 from "../components/Nav2";
import MainLayout from "../layouts/MainLayout";
import { getContact, getMessages, setRead } from "../services/firebase_service";
import { useEffect, useState, Fragment } from "react";
import values from "../values";
import { Dialog, Transition } from '@headlessui/react'
import Fulfill from "../components/Fulfill";
import { ArrowLongLeftIcon, ArrowLongRightIcon, CheckCircleIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { classNames, timeToStr } from "../consts"

const statuses = { Read: 'text-green-400 bg-green-400/10', Unread: 'text-rose-400 bg-rose-400/10' }

function Contact(props) {
    const { id } = useParams();
    return (
        <MainLayout current="Contacts" content={<ContactContent id={id} />} />
    )
}

function ContactContent(props) {
    const id = props.id.split("~").splice(-1)[0]
    const pages = [
        { name: 'Contacts', href: '/contacts', current: false },
        { name: id, href: '/contacts/' + props.id, current: true }
    ]

    const [messages, setMessages] = useState([]);
    const [contact, setContact] = useState([]);
    const [open, setOpen] = useState(false)
    const [afterThis, setAfterThis] = useState(null);
    const [beforeThis, setBeforeThis] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [page, setPage] = useState(0);
    const [show, setShow] = useState(false);

    const markAsRead = async (message) => {
        await setRead(values.site, id, message.id)
        setShow(true)
        setTimeout(() => {
            setShow(false)
        }, "6000");
    }

    const setDocs = (messages, page) => {
        const first_index = 0;
        const last_index = messages.length - 1;

        if (last_index >= 0) {
            setAfterThis(messages[last_index].doc)
        }
        if (page == 0) {
            setBeforeThis(messages[first_index].doc)
        } else {
            setBeforeThis(firstDoc)
        }
        setFirstDoc(messages[first_index].doc)
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const contact = await getContact(values.site, props.id);
                setContact(contact);
                const messages = await getMessages(values.site, props.id, afterThis);
                setMessages(messages);
                setDocs(messages, 0)
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        const updateDivs = async () => {
            await fetchData();
            setTimeout(() => {
                var m = document.getElementsByClassName("div-message");
                for (var i = 0; i < m.length; i++) {
                    if (m.item(i).childNodes[0].clientHeight < 40) {
                        m.item(i).childNodes[1].style.display = "none"
                    }
                }
            }, "300");

        }

        updateDivs()
    }, []);

    async function nextPage() {
        if (messages.length == 10) {
            let new_messages = await getMessages(values.site, props.id, afterThis, null);
            if (new_messages.length > 0) {
                const newPage = page + 1;
                setPage(newPage)
                setDocs(new_messages, newPage);
                setMessages(new_messages);
            }
        }
    }

    async function prevPage() {
        if (page > 0) {
            let new_messages = await getMessages(values.site, props.id, beforeThis, true);
            if (new_messages.length > 0) {
                const newPage = page - 1;
                setPage(newPage)
                setDocs(new_messages, newPage);
                setMessages(new_messages);
            }
        }
    }

    function hide(id, id1) {
        const message = document.getElementById(id);
        message.classList.toggle("line-clamp-3");
        const link = document.getElementById(id1);
        if (link.innerHTML === "View More") {
            link.innerHTML = "View Less";
        } else {
            link.innerHTML = "View More";
        }
    }

    return (
        <div className="Page">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <Fulfill orderNo={props.id} close={() => setOpen(false)} />
                                    <div className="mt-1 sm:mt-2">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="Services bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {id}
                        </h2>
                        <div className="mt-2 border-b border-gray-200 pb-5 text-sm sm:flex sm:justify-between">
                            <dl className="flex">
                                <dt className="text-lg text-gray-500">{contact.name}&nbsp;</dt>
                            </dl>
                            <div className="mt-4 sm:mt-0">
                                <Link to={"mailto:" + id} className="font-medium text-green-600 hover:text-green-500">
                                    Contact User
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                        <h3 className="text-base font-semibold leading-6 text-gray-900">Messages</h3>
                        {/* <div className="mt-3 sm:ml-4 sm:mt-0">
                            <label htmlFor="mobile-search-candidate" className="sr-only">
                                Search
                            </label>
                            <label htmlFor="desktop-search-candidate" className="sr-only">
                                Search
                            </label>
                            <div className="flex rounded-md shadow-sm">
                                <div className="relative flex-grow focus-within:z-10">
                                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                        <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </div>
                                    <input
                                        type="text"
                                        name="mobile-search-candidate"
                                        id="mobile-search-candidate"
                                        className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:hidden"
                                        placeholder="Search Messages"
                                    />
                                    <input
                                        type="text"
                                        name="desktop-search-candidate"
                                        id="desktop-search-candidate"
                                        className="hidden w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:block"
                                        placeholder="Search Messages"
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    <BarsArrowUpIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    Sort
                                    <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </button>
                            </div>
                        </div> */}
                    </div>
                    {messages.length == 0 ?
                        <h3 className="text-lg text-gray-500">No messages</h3>
                        :
                        <div>
                            {show &&
                                <div className="rounded-md bg-green-50 p-4 mt-4">
                                    <div className="flex">
                                        <div className="flex-shrink-0">
                                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                        </div>
                                        <div className="ml-3">
                                            <p className="text-sm font-medium text-green-800">Marked as Read</p>
                                        </div>
                                        <div className="ml-auto pl-3">
                                            <div className="-mx-1.5 -my-1.5">
                                                <button
                                                    type="button"
                                                    onClick={() => setShow(false)}
                                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                                >
                                                    <span className="sr-only">Dismiss</span>
                                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="mt-6 flow-root bg-white pb-5 rounded-md border-2 border-gray-300">
                                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                        <table className="min-w-full divide-y divide-gray-300 bg-white rounded-t-md">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3 rounded-tl-md">
                                                        Status
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Delivered on
                                                    </th>
                                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                        Message
                                                    </th>
                                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3 rounded-tr-md">
                                                        <span className="sr-only">Mark as read</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                {messages.map((message, index) => (
                                                    <tr key={index} className="bg-white divide-x divide-gray-200">
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
                                                            <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                                                                <div className={classNames(statuses[message.data.status], 'flex-none rounded-full p-1')}>
                                                                    <div className="h-1.5 w-1.5 rounded-full bg-current" />
                                                                </div>
                                                                <div className="hidden text-gray-900 sm:block">{message.data.status}</div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 align-top">
                                                            <div className="text-gray-900">{message.data.time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</div>
                                                            <div className="mt-1 text-gray-500">{message.data.time.toDate().toLocaleString('en-US', { hour: "2-digit", minute: '2-digit', hour12: true })}</div>
                                                        </td>
                                                        <td className="div-message whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                                            <pre id={"message-" + index} className="line-clamp-3">{message.data.message}</pre>
                                                            <a id={"link-" + index} onClick={() => hide("message-" + index, "link-" + index)} className="text-green-600 hover:text-green-900 cursor-pointer">
                                                                View More
                                                            </a>
                                                        </td>
                                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3 align-top">
                                                            <a onClick={() => markAsRead(message)} className="text-green-600 hover:text-green-900 cursor-pointer">
                                                                Mark as Read<span className="sr-only">, {contact.name}</span>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-4">
                                    {page != 0 &&
                                        <div className="-mt-px flex w-0 flex-1">
                                            <a
                                                onClick={prevPage}
                                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                            >
                                                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Previous
                                            </a>
                                        </div>}
                                    <div className="-mt-px flex w-0 flex-1 justify-end">
                                        <a
                                            onClick={nextPage}
                                            className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                        >
                                            Next
                                            <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}


export default Contact;