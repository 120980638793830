import { Link } from "react-router-dom";
import Nav2 from "../components/Nav2";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import values from "../values";
import { createTests, getQuotes } from "../services/firebase_service";
import { classNames } from "../consts";

const pages = [
    { name: 'Quotes', href: '/quotes', current: true },
]

const tabs = [
    { name: 'All', href: '#' },
    { name: 'Awaiting Quote', href: '#' },
    { name: 'Quoted', href: '#' },
]

function Quotes() {
    return (
        <MainLayout current="Quotes" content={<QuoteComponent />} />
    )
}

function QuoteComponent() {

    const [quotes, setQuotes] = useState([]);
    const [currentTab, setTab] = useState(0);
    const [afterThis, setAfterThis] = useState(null);
    const [beforeThis, setBeforeThis] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [page, setPage] = useState(0)
    const [whereClause, setWhere] = useState(["Unquoted", "Quoted"])

    const setDocs = (quotes, page) => {
        const first_index = 0;
        const last_index = quotes.length - 1;

        if (last_index >= 0) {
            setAfterThis(quotes[last_index].doc)
        }
        if (page == 0) {
            setBeforeThis(quotes[first_index].doc)
        } else {
            setBeforeThis(firstDoc)
        }
        setFirstDoc(quotes[first_index].doc)
    }


    useEffect(() => {
        async function getElements() {
            let quotes = await getQuotes(values.site, afterThis);
            setQuotes(quotes);
            setDocs(quotes, 0)
        }
        getElements();
    }, []);

    async function nextPage() {
        if (quotes.length == 10) {
            let new_quotes = await getQuotes(values.site, afterThis, null, whereClause);
            if (new_quotes.length > 0) {
                const newPage = page + 1;
                setPage(newPage)
                setDocs(new_quotes, newPage);
                setQuotes(new_quotes);
            }
        }
    }

    async function prevPage() {
        if (page > 0) {
            let new_quotes = await getQuotes(values.site, beforeThis, true, whereClause);
            if (new_quotes.length > 0) {
                const newPage = page - 1;
                setPage(newPage)
                setDocs(new_quotes, newPage);
                setQuotes(new_quotes);
            }
        }
    }

    async function changeTab(tab) {
        if (tab == "Awaiting Quote") {
            tab = "Unquoted"
        }
        const tabs = ["All", "Unquoted", "Quoted"]
        setTab(tabs.indexOf(tab))
        let newWhere = [];
        if (tab != "All") {
            newWhere = [tab]
        } else {
            newWhere = ["Unquoted", "Quoted"]
        }
        setWhere(newWhere)
        let quotes = await getQuotes(values.site, null, null, newWhere);
        setPage(0)
        setQuotes(quotes);
        setDocs(quotes, 0)
    }

    return (
        <div className="Page">
            <div className="Quotes bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Quotes
                        </h2>
                    </div>

                    <br />
                    <div>
                        <div className="sm:hidden">
                            <label htmlFor="tabs" className="sr-only">
                                Select a tab
                            </label>
                            {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                            <select
                                id="tabs"
                                name="tabs"
                                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-green-500 focus:outline-none focus:ring-green-500 sm:text-sm"
                                defaultValue={tabs[currentTab].name}
                                onChange={(e) => changeTab(e.target.value)}
                            >
                                {tabs.map((tab) => (
                                    <option key={tab.name} value={tab.name}>{tab.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="hidden sm:block">
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                    {tabs.map((tab) => (
                                        <Link
                                            key={tab.name}
                                            onClick={() => changeTab(tab.name)}
                                            className={classNames(
                                                tab.name == tabs[currentTab].name
                                                    ? 'border-green-500 text-green-600'
                                                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium'
                                            )}
                                            aria-current={tab.name == tabs[currentTab].name ? 'page' : undefined}
                                        >
                                            {tab.name}
                                        </Link>
                                    ))}
                                </nav>
                            </div>
                        </div>

                        <div className="mt-6">
                            {quotes.length == 0 ? <h5 style={{ "fontWeight": "300" }} class="mb-0">
                                No Quotes
                            </h5> : <TabsAndTable quotes={quotes} />}
                        </div>
                        <br />

                        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                            {page != 0 &&
                                <div className="-mt-px flex w-0 flex-1">
                                    <a
                                        onClick={prevPage}
                                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                    >
                                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        Previous
                                    </a>
                                </div>
                            }
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                <a
                                    onClick={nextPage}
                                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                >
                                    Next
                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </a>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

function TabsAndTable(props) {

    return (
        <>

            <div className="flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead className="ml-4">
                                <tr>
                                    <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        <span className="ml-4">User Name</span>
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Service
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Status
                                    </th>
                                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Date
                                    </th>
                                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                        <span className="sr-only">Edit</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {props.quotes.map((quote) => (
                                    <tr key={quote.id}>
                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                            <div className="flex items-center">
                                                <div className="ml-4">
                                                    <div className="font-medium text-gray-900">{quote.name}</div>
                                                    {/* <div className="mt-1 text-gray-500">{quote.email}</div> */}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                            <div className="text-gray-600">{quote.service} ({quote.pkg} Package)</div>
                                            {/* <div className="mt-1 text-gray-500">{quote.pkg} Package</div> */}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                            {quote.status == "Quoted" ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                Quoted
                                            </span> : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                Awaiting Quote
                                            </span>}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{quote.time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</td>
                                        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                            <Link to={"/quotes/" + quote.id} className="text-green-600 hover:text-green-900 mr-4">
                                                Manage Quote
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Quotes