import { auth, db } from "../firebaseConfig";
import { collection, query, where, getDoc, getDocs, orderBy, startAfter, startAt, doc, limit, setDoc, Timestamp, addDoc, updateDoc, increment, deleteDoc } from "firebase/firestore";
import { Site } from "./site_class";
import { Gallery, Package, Pricing, Question, Service } from "./service_class";
import { Order } from "./order_service";
import { Contact } from "./contact_service";
import values from "../values";
import { Quote } from "./quote_service";
import { signOut } from "firebase/auth";

const presetSettings = {
    "account-general": "",
    "account-social-links": "",
    "account-payments": "",
    "account-billing": "",
    "account-domains": "",
}

export async function initSite() {
    const q = query(collection(db, 'sites'), where("owner", "==", auth.currentUser.uid))
    const querySnap = await getDocs(q);
    if(querySnap.docs.length != 0) {
        let data = querySnap.docs[0].data();
        values.site = querySnap.docs[0].id;
        values.site_class = new Site(data.name, querySnap.docs[0].id, data.icon, data.colors, data.slogan, data.revenue, data.orders, data.sessions, data.accountID, data.insta, data.twitter, data.linkedin, data.customerID, data.google_tag);
        return true
    } else {
        values.site = null;
        values.site_class = null;
        return false
    }
}

export async function getSite(name) {
    const docSnap = await getDoc(doc(db, 'sites', name));
    if(docSnap.exists()) {
        let data = docSnap.data();
        return new Site(data.name, docSnap.id, data.icon, data.colors, data.slogan, data.revenue, data.orders, data.sessions, data.accountID, data.insta, data.twitter, data.linkedin, data.customerID, data.google_tag);
    } else {
        // TODO - fail
        return;
    }
}

export async function getCustomer(id) {
    const docSnap = await getDoc(doc(db, 'customers', id));
    if(docSnap.exists()) {
        return docSnap.data();
    } else {
        // TODO - fail
        return;
    }
}

export async function getService(site, serviceId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/services/${serviceId}`));
    if(docSnap.exists()) {
        let data = docSnap.data();
        const requirements = []
        for (var i = 0; i < data.questions.length; i++) {
            requirements.push(new Question().from_json(data.questions[i]))
        }
        return new Service(data.site, data.name, new Pricing(new Package().from_json(data.good), new Package().from_json(data.better), new Package().from_json(data.best)), data.description, requirements, new Gallery(data.images, data.yt_videos), docSnap, data.sessions, data.revenue, data.orders);
    } else {
        // TODO - fail
        return;
    }
}

export async function getOrder(site, orderId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/orders/${orderId}`));
    if(docSnap.exists()) {
        let data = docSnap.data(); 
        return new Order().from_json(docSnap.id, data); 
    } else {
        // TODO - fail
        return;
    }
}

export async function getContact(site, contactId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/contacts/${contactId}`));
    if(docSnap.exists()) {
        let data = docSnap.data();
        return new Contact().from_json(site, docSnap.id, data, docSnap);
    } else {
        // TODO - fail
        return;
    }
}

export async function getQuote(site, orderId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/quotes/${orderId}`));
    if(docSnap.exists()) {
        let data = docSnap.data(); 
        return new Quote().from_json(docSnap.id, data); 
    } else {
        // TODO - fail
        return;
    }
}

export async function getFulfillment(site, fulfillmentId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/fulfillments/${fulfillmentId}`));
    if(docSnap.exists()) {
        let data = docSnap.data();
        return data;
    } else {
        // TODO - fail
        return;
    }
}

export async function getSent(site, sentId) {
    const docSnap = await getDoc(doc(db, `sites/${site}/sent-quotes/${sentId}`));
    if(docSnap.exists()) {
        let data = docSnap.data();
        return data;
    } else {
        // TODO - fail
        return;
    }
}

export async function getMessages(site, email, lastDoc, before) {
    let q = null;
    if(lastDoc == null) {
        q = query(collection(db, "sites", site, "contacts", email, "messages"), orderBy("time", "desc"), limit(10));
    }  else if(before == null)  {
        q = query(collection(db, "sites", site, "contacts", email, "messages"), orderBy("time", "desc"), startAfter(lastDoc), limit(10));
    } else {
        q = query(collection(db, "sites", site, "contacts", email, "messages"), orderBy("time", "desc"), startAt(lastDoc), limit(10));
    }
    const querySnapshot = await getDocs(q);
    let messages = []
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        messages.push({"id": doc.id, "data": data})
    });
    return messages;
}

export async function getServices(site, lastDoc, before) {
    let q = null;
    if(lastDoc == null) {
        q = query(collection(db, "sites", site, "services"), orderBy("name"), limit(12));
    }  else if(before == null)  {
        q = query(collection(db, "sites", site, "services"), orderBy("name"), startAfter(lastDoc), limit(12));
    } else {
        q = query(collection(db, "sites", site, "services"), orderBy("name"), startAt(lastDoc), limit(12));
    }
    const querySnapshot = await getDocs(q);
    let services = [];
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        const requirements = []
        for (var i = 0; i < data.questions.length; i++) {
            requirements.push(new Question().from_json(data.questions[i]))
        }
        services.push(new Service(site, data.name, new Pricing(new Package().from_json(data.good), new Package().from_json(data.better), new Package().from_json(data.best)), data.description, requirements, new Gallery(data.images, data.yt_videos), doc, data.sessions, data.revenue, data.orders),)
    });
    return services;
}

export async function getOrders(site, lastDoc, before, whereClause=["Unfulfilled", "Fulfilled"]) {
    let q = null;
    if(lastDoc == null) {
        q = query(collection(db, "sites", site, "orders"), where("status", "in", whereClause), orderBy("time", "desc"), limit(10));
    } else if(before == null) {
        q = query(collection(db, "sites", site, "orders"), where("status", "in", whereClause), orderBy("time", "desc"), startAfter(lastDoc), limit(10));
    } else {
        q = query(collection(db, "sites", site, "orders"), where("status", "in", whereClause), orderBy("time", "desc"), startAt(lastDoc), limit(10));
    }
    const querySnapshot = await getDocs(q);
    let orders = [];
    querySnapshot.forEach((doc) => {
        orders.push(new Order().from_json(doc.id, doc.data(), doc))
    });
    return orders;
}

export async function getQuotes(site, lastDoc, before, whereClause=["Unquoted", "Quoted"]) {
    let q = null;
    if(lastDoc == null) {
        q = query(collection(db, "sites", site, "quotes"), where("status", "in", whereClause), orderBy("time", "desc"), limit(10));
    } else if(before == null) {
        q = query(collection(db, "sites", site, "quotes"), where("status", "in", whereClause), orderBy("time", "desc"), startAfter(lastDoc), limit(10));
    } else {
        q = query(collection(db, "sites", site, "quotes"), where("status", "in", whereClause), orderBy("time", "desc"), startAt(lastDoc), limit(10));
    }
    const querySnapshot = await getDocs(q);
    let orders = [];
    querySnapshot.forEach((doc) => {
        orders.push(new Quote().from_json(doc.id, doc.data(), doc))
    });
    return orders;
}

export async function getContacts(site, lastDoc, before) {
    let q = null;
    if(lastDoc == null) {
        q = query(collection(db, "sites", site, "contacts"), orderBy("unread", "desc"), limit(10));
    } else if (before == null) {
        q = query(collection(db, "sites", site, "contacts"), orderBy("unread", "desc"), startAfter(lastDoc), limit(10));
    } else {
        q = query(collection(db, "sites", site, "contacts"), orderBy("unread", "desc"), startAt(lastDoc), limit(10));
    }
    const querySnapshot = await getDocs(q);
    let contacts = [];
    querySnapshot.forEach((doc) => {
        contacts.push(new Contact().from_json(values.site, doc.id, doc.data(), doc))
    });
    return contacts;
}

export async function getSettings() {
    // get settings from auth.user
    const userId = auth.currentUser.uid;
    const userSettingsRef = collection(db, 'users', userId, 'settings', 'mainSettings');
    const userSettingsSnapshot = await userSettingsRef.get();

    if (userSettingsSnapshot.exists) {
      // The settings document exists, so you can access its data
      const settingsData = userSettingsSnapshot.data();
      return settingsData;
    } else {
      await userSettingsRef.set(presetSettings);
      return presetSettings;
    }
}

export async function fulfillOrder(site, orderNo, type, file, email) {
    var fulfillmentId = await addDoc(collection(db, 'sites', site, 'fulfillments'), {
        "type": type,
        "file": file,
        "time": Timestamp.now(),
        "email": email,
    })

    await updateDoc(doc(db, 'sites', site, 'orders', orderNo), {
        "status": "Fulfilled",
        "fulfillment": fulfillmentId.id
    })
}

export async function sendQuote(site, quoteNo, price, email, url) {
    var sentQuote = await addDoc(collection(db, 'sites', site, 'sent-quotes'), {
        "price": price,
        "time": Timestamp.now(),
        "email": email,
        "url": url,
        "quote": quoteNo
    })

    await updateDoc(doc(db, 'sites', site, 'quotes', quoteNo), {
        "status": "Quoted",
        "quote": sentQuote.id
    })
}

export async function setRead(site, contact, messageId) {
    await updateDoc(doc(db, 'sites', site, 'contacts', contact, "messages", messageId), {
        "status": "Read",
    })
    await updateDoc(doc(db, 'sites', site, 'contacts', contact), {
        "unread": increment(-1),
    })
}

export async function getBilling() {
    // get billing from auth.user
    
}

export async function getDeliveries() {

}

export async function getDelivery() {

}

export async function createTests() {
    for(let i = 0; i < 12; i++) {
        await setDoc(doc(db, "sites", values.site, "quotes", "haroons-web-development~test"+ i), {
            "name": "Jenny Rosen",
            "package": "Good",
            "responses": [
                {
                    "response": "a",
                    "response_type": "Free Response"
                },
                {
                    "response_type": "Free Response",
                    "response": "a"
                },
                {
                    "response_type": "Free Response",
                    "response": "a"
                },
                {
                    "response": "a",
                    "response_type": "Free Response"
                },
                {
                    "response": "a",
                    "response_type": "Free Response"
                },
                {
                    "response": "a",
                    "response_type": "Free Response"
                }
            ],
            "email": "stripe@example.com",
            "service": "Web Development",
            "time": Timestamp.now(),
            "status": "Unquoted"
        }
        )
    }
}

export async function createContactTests() {
    for(let i = 0; i < 12; i++) {
        await setDoc(doc(db, "sites", values.site, "contacts", "test~"+i), {
            "lastMessage": Timestamp.now(),
            "unread": 0,
            "name": "Jenny Rosen"
        })
    }
}

export async function copyDoc(sourceDoc, destDoc) {
    const docSnap = await getDoc(doc(db, sourceDoc))
    const data = docSnap.data();
    const destinationDocRef = doc(db, destDoc);
    await setDoc(destinationDocRef, data);
}

export async function deleteService(site, service) {
    await deleteDoc(doc(db, "sites", site, "services", service));
}

export async function getTopOrdered(site) {
    const q = query(collection(db, 'sites', site, 'services'), orderBy("orders", "desc"), limit(4));
    return getServiceDocuments(q);
}

export async function getTopRevenue(site) {
    const q = query(collection(db, 'sites', site, 'services'), orderBy("revenue", "desc"), limit(4));
    return getServiceDocuments(q);
}

export async function getTopViewed(site) {
    const q = query(collection(db, 'sites', site, 'services'), orderBy("sessions", "desc"), limit(4));
    return getServiceDocuments(q);
}

async function getServiceDocuments(q) {
    const querySnapshot = await getDocs(q);
    let services = [];
    querySnapshot.forEach((doc) => {
        let data = doc.data();
        const requirements = []
        for (var i = 0; i < data.questions.length; i++) {
            requirements.push(new Question().from_json(data.questions[i]))
        }
        services.push(new Service(values.id, data.name, new Pricing(new Package().from_json(data.good), new Package().from_json(data.better), new Package().from_json(data.best)), data.description, requirements, new Gallery(data.images, data.yt_videos), doc, data.sessions, data.revenue, data.orders),)
    });
    return services;
}

export async function getLastOrders(site) {
    const q = query(collection(db, 'sites', site, 'orders'), orderBy("time", "desc"), limit(10));
    return getOrderDocuments(q);
}

async function getOrderDocuments(q) {
    const querySnapshot = await getDocs(q);
    let orders = [];
    querySnapshot.forEach((doc) => {
        orders.push(new Order().from_json(doc.id, doc.data(), doc))
    });
    return orders;
}