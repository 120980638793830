import './App.css';
import React, { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Settings from "./pages/Settings";
import './styles/Page.css';
import Login from './pages/Login';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebaseConfig";
import ForgotPassword from './pages/ForgotPassword';
import Checkout from './pages/Checkout';
import values from './values';
import Services from './pages/Services';
import { initSite } from './services/firebase_service';
import Service from './pages/Service';
import Orders from './pages/Orders';
import Contacts from './pages/Contacts';
import Create from './pages/Create';
import Register from './pages/Register'
import Order from './pages/Order';
import Quotes from './pages/Quotes';
import Quote from './pages/Quote';
import Contact from './pages/Contact';
import Edit from './pages/Edit';

function App() {
  const [component, setComponent] = useState(0);
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      if(component != "pages") {
        let output = await initSite();
        if(output == true) {
          setComponent(
            "pages"
          );
        }
      }
    } else {
      if(component != "login") {
        setComponent(
          "login"
        );
      }
    }
  });

  return (
    component == "pages" ?
      <BrowserRouter>
        <Routes>
          <Route index element={<Dashboard />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<Order />} />
          <Route path="contacts" element={<Contacts />} />
          <Route path="contacts/:id" element={<Contact />} />
          <Route path="create" element={<Create />} />
          <Route path="edit/:id" element={<Edit />} />
          <Route path="settings" element={<Settings />} />
          <Route path="services" element={<Services site={values.site} />} />
          <Route path="services/:id" element={<Service />} />
          <Route path="quotes" element={<Quotes />} />
          <Route path="quotes/:id" element={<Quote />} />
          <Route path="*" element={<Dashboard />} />
        </Routes>
      </BrowserRouter> : 
      component == "login" ?
      <BrowserRouter>
        <Routes>
          <Route index element={<Login auth={auth}  />} />
          <Route path="register" element={<Register auth={auth} update={setComponent}  />} />
          <Route path="forgotpassword" element={<ForgotPassword  auth={auth} />} />
          <Route path="*" element={<Login auth={auth} />} />
        </Routes>
      </BrowserRouter>
      :
      <br />
  );
}

export default App;