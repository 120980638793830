import React, { useState } from "react";
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import Nav from "../components/Nav";
import { Link } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import values from "../values";
import { classNames, stripify } from "../consts";
import { useEffect } from "react";
import { getCustomer, getLastOrders, getSite, getTopOrdered, getTopRevenue, getTopViewed } from "../services/firebase_service";

function Dashboard() {
  return (
    <MainLayout current="Dashboard" content={<DashboardContent />} />
  );
}

function DashboardContent() {

  const [notify, setNotify] = useState("")

  useEffect(() => {
    async function loadSite() {
      let site = await getSite(values.site)
      let customer = await getCustomer(site.customerID)
      console.log(customer)
      if (customer != null) {
        setNotify(customer.notify)
      }
    }

    loadSite()
  }, [])

  return (
    <>
      <Nav />
      <br /><br />
      {values.site_class.customerID == null ? <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              To get your website running, you must set up billing. {' '}
              <Link to="/settings" className="font-medium text-yellow-700 underline hover:text-yellow-600">
                Activate Free Trial
              </Link>
            </p>
          </div>
        </div>
      </div> : (notify == "" ?
        <>
        </>
        :
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                {notify}
              </p>
            </div>
          </div>
        </div>)
      }
      <br />
      <DashboardStats />
      <br /><br />
      <DashboardOrders />
      <br /><br />
      <TopRevenue />
      <br /><br />
      <TopOrdered />
      <br /><br />
      <TopViewed />
    </>
  )
}

function DashboardStats() {
  return (
    <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 rounded-md">
      <div
        key={1}
        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">Revenue</dt>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          ${values.site_class.revenue != null ? stripify(values.site_class.revenue) : "0.00"}
        </dd>
      </div>
      <div
        key={2}
        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">Orders</dt>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {values.site_class.orders != null ? values.site_class.orders : 0}
        </dd>
      </div>
      <div
        key={3}
        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">Sessions</dt>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {values.site_class.sessions != null ? values.site_class.sessions : 0}
        </dd>
      </div>
      <div
        key={4}
        className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
      >
        <dt className="text-sm font-medium leading-6 text-gray-500">Conversion Rate</dt>
        <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
          {values.site_class.orders != null && values.site_class.sessions ? (values.site_class.orders / values.site_class.sessions).toFixed(2) : "0.00"}%
        </dd>
      </div>
    </dl>
  )
}

function DashboardOrders() {

  const [orders, setOrders] = useState([])

  useEffect(() => {
    async function fetchTop() {
      let orders = await getLastOrders(values.site);
      setOrders(orders);
    }

    fetchTop();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-7xl">
        <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none">
          Recent activity
        </h2>
        <div className="mt-4 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      Order ID
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Service
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Package
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Payment
                    </th>
                    <th
                      scope="col"
                      className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Fulfillment Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {orders.map((order) => (
                    <tr key={order.id}>
                      <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-0">{order.id.split("~")[1]}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                        {order.email}
                      </td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900">{order.service}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{order.pkg}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">${stripify(order.payment)}</td>
                      <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500">{order.status == "Fulfilled" ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                        Fulfilled
                      </span> : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                        Unfulfilled
                      </span>}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


function TopViewed() {

  const [offerings, setOfferings] = useState([])

  useEffect(() => {
    async function fetchTop() {
      let offerings = await getTopViewed(values.site);
      setOfferings(offerings);
    }

    fetchTop();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-7xl">
        <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none  mb-5">
          Top Viewed Offerings
        </h2>
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {offerings.map((service) => (
            <Link to={"/services/" + service.name} className="linkNoStyle">
              <li key={service.name} className="relative">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg border bg-white focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 text-center">
                  {service.gallery.images != null && service.gallery.images.length > 0 && <img src={service.gallery.images[0]} alt="" className="pointer-events-none object-cover group-hover:opacity-75 inline-block" />}
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {service.name}</span>
                  </button>
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{service.name}</p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">{service.sessions} Views</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

function TopOrdered() {

  const [offerings, setOfferings] = useState([])

  useEffect(() => {
    async function fetchTop() {
      let offerings = await getTopOrdered(values.site);
      setOfferings(offerings);
    }

    fetchTop();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-7xl">
        <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none mb-5">
          Top Ordered Offerings
        </h2>
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {offerings.map((service) => (
            <Link to={"/services/" + service.name} className="linkNoStyle">
              <li key={service.name} className="relative">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-white border focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 text-center">
                  {service.gallery.images != null && service.gallery.images.length > 0 && <img src={service.gallery.images[0]} alt="" className="pointer-events-none object-cover group-hover:opacity-75 inline-block" />}
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {service.name}</span>
                  </button>
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{service.name}</p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">{service.orders} Orders</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

function TopRevenue() {

  const [offerings, setOfferings] = useState([])

  useEffect(() => {
    async function fetchTop() {
      let offerings = await getTopRevenue(values.site);
      setOfferings(offerings);
    }

    fetchTop();
  }, []);

  return (
    <div>
      <div className="mx-auto max-w-7xl">
        <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none mb-5">
          Top Revenue Offerings
        </h2>
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {offerings.map((service) => (
            <Link to={"/services/" + service.name} className="linkNoStyle">
              <li key={service.name} className="relative">
                <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-white border focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 text-center">
                  {service.gallery.images != null && service.gallery.images.length > 0 && <img src={service.gallery.images[0]} alt="" className="pointer-events-none object-cover group-hover:opacity-75 inline-block" />}
                  <button type="button" className="absolute inset-0 focus:outline-none">
                    <span className="sr-only">View details for {service.name}</span>
                  </button>
                </div>
                <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{service.name}</p>
                <p className="pointer-events-none block text-sm font-medium text-gray-500">${service.revenue}</p>
              </li>
            </Link>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default Dashboard;