import React, { useEffect, useState, Fragment } from "react";
import { useParams, Link } from "react-router-dom";
import Nav2 from "../components/Nav2";
import { deleteService, getService } from "../services/firebase_service";
import values from "../values";
import MainLayout from "../layouts/MainLayout";
import gif from "../loading.gif"
import { classNames, stripify } from "../consts";
import { Dialog, Transition } from '@headlessui/react'

const Service = props => {
    const { id } = useParams();
    let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
    regionNames.of('US');

    return (
        <MainLayout current="Services" content={<ServiceComponent id={id} />} />
    );
}

const stats = [
    { name: 'Revenue', value: '$405,091.00', change: '+4.75%', changeType: 'positive' },
    { name: 'Orders', value: '4,000', change: '+54.02%', changeType: 'positive' },
    { name: 'Sessions', value: '89,000', change: '-1.39%', changeType: 'positive' },
    { name: 'Conversion Rate', value: '25.31%', change: '+10.18%', changeType: 'positive' },
]

function ServiceComponent(props) {

    const [service, setService] = useState(null);
    const [open, setOpen] = useState(false)

    const pages = [
        { name: 'Services', href: '/services', current: false },
        { name: props.id, href: '/services/' + props.id, current: true }
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const service = await getService(values.site, props.id);
                setService(service);
                console.log(service.gallery.images)
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="Page">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <h3>Are you sure you want to delete this?</h3>
                                    <div className="mt-1 sm:mt-2">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                            onClick={() => deleteService(values.id, props.id)}
                                        >
                                            Delete
                                        </button>
                                    </div>
                                    <div className="mt-1 sm:mt-2">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="Services bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            {props.id}
                        </h2>
                        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
                            <Link to={"/edit/" + props.id}>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    Edit Service
                                </button>
                            </Link>
                            <Link to="#" onClick={() => setOpen(true)}>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                >
                                    Delete Service
                                </button>
                            </Link>
                        </div>
                    </div>
                    {service != null ?
                        <div>
                            <br />
                            <dl className="mx-auto grid grid-cols-1 gap-px bg-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 rounded-md">
                                <div
                                    key={1}
                                    className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
                                >
                                    <dt className="text-sm font-medium leading-6 text-gray-500">Revenue</dt>
                                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        ${service.revenue != null ? stripify(service.revenue) : "0.00"}
                                    </dd>
                                </div>
                                <div
                                    key={2}
                                    className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
                                >
                                    <dt className="text-sm font-medium leading-6 text-gray-500">Orders</dt>
                                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        {service.orders != null ? service.orders : 0}
                                    </dd>
                                </div>
                                <div
                                    key={3}
                                    className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
                                >
                                    <dt className="text-sm font-medium leading-6 text-gray-500">Sessions</dt>
                                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        {service.sessions != null ? service.sessions : 0}
                                    </dd>
                                </div>
                                <div
                                    key={4}
                                    className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 bg-white-100 px-4 py-10 sm:px-6 xl:px-8"
                                >
                                    <dt className="text-sm font-medium leading-6 text-gray-500">Conversion Rate</dt>
                                    <dd className="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900">
                                        {service.orders != null && service.sessions ? (service.orders / service.sessions).toFixed(2) : "0.00"}
                                    </dd>
                                </div>
                            </dl>
                            <br />
                            <div className="px-4 sm:px-0">
                                <h3 className="text-base font-semibold leading-7 text-gray-900">Basic Information</h3>
                                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Information about your service.</p>
                            </div>
                            <div className="mt-6 border-t border-gray-100">
                                <dl className="divide-y divide-gray-100">
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Name</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{service.name}</dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Packages</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{service.pricing.good.name}, {service.pricing.better.name}, {service.pricing.best.name}</dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Description</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            <pre>
                                                {service.description}
                                            </pre>
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Questions</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                                            <ul>
                                                {service.requirements.map((question, index) => (
                                                    <li>{index + 1}. {question.question}</li>
                                                ))}
                                            </ul>
                                        </dd>
                                    </div>
                                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">Images</dt>
                                        <dd className="mt-1 grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                                            {service.gallery.images.map((image, index) => (
                                                <img src={image} />
                                            ))}
                                        </dd>
                                    </div>
                                </dl>
                            </div>
                        </div> : <div id="loading-content">
                            <img src={gif} />
                        </div>}

                    {/* TODO - delete and update */}
                </div>
            </div>
        </div>
    )
}

export default Service;