import { Link, useParams } from "react-router-dom";
import Nav2 from "../components/Nav2";
import MainLayout from "../layouts/MainLayout";
import { getOrder, getService, getFulfillment } from "../services/firebase_service";
import { useEffect, useState, Fragment } from "react";
import values from "../values";
import { Dialog, Transition } from '@headlessui/react'
import Fulfill from "../components/Fulfill";
import { stripify } from "../consts";


function Order(props) {
    const { id } = useParams();
    return (
        <MainLayout current="Orders" content={<OrderContent id={id} />} />
    )
}

function OrderContent(props) {
    const id = props.id.split("~").splice(-1)[0]
    const pages = [
        { name: 'Orders', href: '/orders', current: false },
        { name: id, href: '/orders/' + props.id, current: true }
    ]

    const [data, setData] = useState([]);
    const [order, setOrder] = useState([]);
    const [service, setService] = useState([]);
    const [fulfillment, setFulfillment] = useState(null)
    const [open, setOpen] = useState(false)


    useEffect(() => {
        const fetchData = async () => {
            try {
                const order = await getOrder(values.site, props.id);
                const service = await getService(values.site, order.service);
                if(order.fulfillment != null) {
                    const fulfillment = await getFulfillment(values.site, order.fulfillment)
                    setFulfillment(fulfillment)
                }
                setOrder(order);
                setService(service);
                setData(mergeQuestionsAndResponses(service.requirements, order.responses))
            } catch (error) {
                console.error('Error fetching responses:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="Page">
            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <Fulfill orderNo={props.id} email={order.email} close={() => setOpen(false)} />
                                    <div className="mt-1 sm:mt-2">
                                        <button
                                            type="button"
                                            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                                            onClick={() => setOpen(false)}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>
            <div className="Services bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div>
                        <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Order Details
                        </h2>
                        <div className="mt-2 border-b border-gray-200 pb-5 text-sm sm:flex sm:justify-between">
                            <dl className="flex">
                                <dt className="text-sm text-gray-500">Order number&nbsp;</dt>
                                <dd className="text-sm text-gray-900">{id.toUpperCase()}</dd>
                                <dt>
                                    <span className="sr-only">Date</span>
                                    <span className="mx-2 text-gray-400" aria-hidden="true">
                                        &middot;
                                    </span>
                                </dt>
                                <dt className="text-sm text-gray-500">Ordered on&nbsp;</dt>
                                <dd className="text-sm text-gray-900">
                                    <time>{order.time != null && order.time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</time>
                                </dd>
                            </dl>
                            <div className="mt-4 sm:mt-0">
                                <Link onClick={() => setOpen(true)}className="font-medium text-green-600 hover:text-green-500">
                                    Fulfill Order
                                    <span aria-hidden="true"> &rarr;</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div>
                        <div className="px-4 sm:px-0 pt-10">
                            <h3 className="text-base font-semibold leading-7 text-gray-900">Order Information</h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Personal details and status.</p>
                        </div>
                        <div className="mt-6 border-b border-gray-900/10 pb-12">
                            <dl className="grid grid-cols-1 sm:grid-cols-2">
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Customer Name</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{order.name}</dd>
                                </div>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                    {order.status == "Fulfilled" ? <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                        Fulfilled
                                    </span> : <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                        Unfulfilled
                                    </span>}
                                </div>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{order.email}</dd>
                                </div>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Payment Amount</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">${stripify(order.payment)}</dd>
                                </div>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Service Bought</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{order.service}</dd>
                                </div>
                                <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Package Purchased</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{order.pkg}</dd>
                                </div>
                            </dl>
                        </div>
                        {order.status == "Fulfilled" && fulfillment != null ? <div className="mt-12 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Fulfillment</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    Your fulfillment will show up here.
                                </p>
                            </div>

                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                <div className="border-t border-gray-150 px-4 py-6 col-span-full">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Date Fulfilled</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                        {fulfillment.time.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}
                                    </dd>
                                </div>
                                <div className="border-t border-gray-150 px-4 py-6 col-span-full">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">Type of Fulfillment</dt>
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                        {fulfillment.type}
                                    </dd>
                                </div>
                                {fulfillment.type == "Electronic Delivery" ? <div className="border-t border-gray-150 px-4 py-6 col-span-full">
                                    <dt className="text-sm font-medium leading-6 text-gray-900">File</dt>
                                    <dd className="leading-6">
                                        {fulfillment.file != null && <a className="underline text-green-600 hover:text-green-800 visited:text-green-600" href={fulfillment.file.url}>Link to Document</a>}
                                    </dd>
                                </div> : null }
                            </div>
                        </div> : null }
                        <div className="mt-12 grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Responses</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">
                                    These were the responses to the questions you asked.
                                </p>
                            </div>

                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                {data.map((item, index) => (
                                    <div className="border-t border-gray-100 px-4 py-6 col-span-full">
                                        <dt className="text-sm font-medium leading-6 text-gray-900">{item.question}</dt>
                                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                                            {item.response == "File" ? <Link to={item.response}>Link to File</Link> : item.response}
                                        </dd>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mergeQuestionsAndResponses(questions, responses) {
    if (questions.length !== responses.length) {
        throw new Error("Questions and responses must have the same length");
    }

    return questions.map((question, index) => {
        return {
            question: question.question,
            response_type: question.response_type,
            response: responses[index].response,
        };
    });
}


export default Order;