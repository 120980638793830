import React, { useState, useEffect } from "react";
import Nav2 from "../components/Nav2";
import "../styles/Settings.css"
import { SwatchesPicker } from "react-color";
import values from "../values";
import { getSite } from "../services/firebase_service";
import { Site } from "../services/site_class";
import gif from "../loading.gif"
import MainLayout from "../layouts/MainLayout";
import {
    CreditCardIcon,
    CubeIcon,
    LinkIcon,
    TagIcon,
    UserCircleIcon,
} from '@heroicons/react/24/outline'
import { auth, db } from "../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { ArrowLeftIcon, CheckCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { classNames, stripify } from "../consts";

const pages = [
    { name: 'Settings', href: '/settings', current: true },
]

const steps = [
    { name: 'Buy Domain', href: '#', status: 'current' },
    { name: 'CNAME Record', href: '#', status: 'upcoming' },
    { name: 'Verify', href: '#', status: 'upcoming' },
]

function Settings() {
    return (
        <MainLayout current="Settings" content={<SettingsComponent />} />
    )
}

function SettingsComponent() {

    const [activeTab, setActiveTab] = useState('account-site');
    const [site, setSite] = useState(null);

    // Function to handle tab switching
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const secondaryNavigation = [
        { name: 'Site Details', tab: 'account-site', icon: UserCircleIcon, current: true },
        { name: 'Social Links', tab: 'account-social-links', icon: LinkIcon, current: false },
        { name: 'Payments', tab: 'account-payments', icon: CubeIcon, current: false },
        { name: 'Billing', tab: 'account-billing', icon: CreditCardIcon, current: false },
        { name: 'Domains', tab: 'account-domains', icon: TagIcon, current: false },
    ]

    useEffect(() => {
        async function getSiteDetails() {
            let site = await getSite(values.site);
            setSite(site)
        }

        getSiteDetails()
    }, []);

    return (
        <div className="Page">
            <div className="Settings bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Settings
                        </h2>
                    </div>
                    <br />


                    <div className="mx-auto max-w-7xl pt-0 lg:flex lg:gap-x-4 lg:px-0 lg:pr-8">
                        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-10">
                            <nav className="flex-none px-4 sm:px-6 lg:px-0">
                                <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
                                    {secondaryNavigation.map((item) => (
                                        <li key={item.name}>
                                            <a
                                                onClick={() => handleTabChange(item.tab)}
                                                className={classNames(
                                                    item.tab == activeTab
                                                        ? 'bg-gray-50 text-green-600'
                                                        : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                                                    'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold cursor-pointer'
                                                )}
                                            >
                                                <item.icon
                                                    className={classNames(
                                                        item.tab == activeTab ? 'text-green-600' : 'text-gray-400 group-hover:text-green-600',
                                                        'h-6 w-6 shrink-0'
                                                    )}
                                                    aria-hidden="true"
                                                />
                                                {item.name}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </aside>

                        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-10">
                            {activeTab == "account-site" && <SiteDetails site={site} />}
                            {activeTab == "account-social-links" && <SocialLinks site={site} />}
                            {activeTab == "account-payments" && <Payments site={site} />}
                            {activeTab == "account-billing" && <Billing site={site} />}
                            {activeTab == "account-domains" && <Domains steps={steps} />}
                        </main>
                    </div>
                </div>
            </div>
        </div>
    );
}

function SiteDetails(props) {

    const [name, setName] = useState("");
    const [url, setURL] = useState("");
    const [icon, setIcon] = useState(null);
    const [newicon, setNewIcon] = useState(null)
    const [color, setColor] = useState("");
    const [slogan, setSlogan] = useState("");
    const [site, setSite] = useState(null);
    const [loading, setLoading] = useState(false)

    const saveSite = async (e) => {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            setLoading(true)
            const s = new Site(name, url, newicon, color, slogan, props.site.sales, props.site.orders, props.site.sessions)
            setIcon(await s.updateSite(icon))
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.site !== site) {
            setName(props.site.name)
            setURL(props.site.url)
            setIcon(props.site.icon)
            setColor(props.site.colors)
            setSlogan(props.site.slogan)
            setSite(props.site)
        }
    }, [props.site]);

    return (
        <>
            {loading == true ? <div id="loading-content">
                <img src={gif} />
            </div> :
                <form id="sign-up">

                    <div className="px-4 sm:px-0">
                        <h3 className="text-base font-semibold leading-7 text-gray-900">Site Details</h3>
                        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">All the things about your brand.</p>
                    </div>

                    <div className='mt-6 border-t border-gray-300 py-6'>
                        <label htmlFor="sitename" className="block text-sm font-medium leading-6 text-gray-900">
                            Site name
                        </label>
                        <div className="mt-2">
                            <input
                                id="sitename"
                                name="sitename"
                                autoComplete="sitename"
                                placeholder="John's Web Development"
                                required
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                            />
                        </div>
                    </div>

                    <div className="col-span-full mt-5">
                        <label htmlFor="siteicon" className="block text-sm font-medium leading-6 text-gray-900">
                            Site icon
                        </label>
                        <div className="mt-2">
                            <p className="text-sm font-medium leading-6 text-gray-600">Current Icon</p>
                            <img src={icon} width={60} />
                            <br></br>
                            <p className="text-sm font-medium leading-6 text-gray-600">Select Another Icon</p>
                            <input className="text-sm text-stone-500
                                file:mr-5 file:py-1 file:px-3 file:border-[1px]
                                file:text-xs file:font-medium
                                file:bg-stone-50 file:text-stone-700
                                hover:file:cursor-pointer hover:file:bg-green-50
                                hover:file:text-green-700" id="siteicon" type="file" onChange={(e) => setNewIcon(e.target.files[0])} />
                        </div>
                    </div>

                    <div className='mt-5'>
                        <label htmlFor="siteurl" className="block text-sm font-medium leading-6 text-gray-900">
                            Site Color
                        </label>
                        <SwatchesPicker
                            className="mt-2"
                            color={color}
                            onChangeComplete={(color) => setColor(color.hex)}
                        />
                    </div>


                    <div className='mt-5'>
                        <label htmlFor="siteslogan" className="block text-sm font-medium leading-6 text-gray-900">
                            Site slogan
                        </label>
                        <div className="mt-2">
                            <input
                                id="siteslogan"
                                name="siteslogan"
                                autoComplete="siteslogan"
                                required
                                value={slogan}
                                onChange={(e) => setSlogan(e.target.value)}
                                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                            />
                        </div>
                    </div>

                    <div class="mt-7">
                        <button
                            type="button"
                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            onClick={saveSite}
                        >
                            Update
                        </button>
                    </div>
                </form>
            }
        </>
    )
}


function SocialLinks(props) {

    const [loading, setLoading] = useState(false)
    const [insta, setInsta] = useState("")
    const [twitter, setTwitter] = useState("")
    const [linkedin, setLinkedin] = useState("")
    const [google_tag, setGoogleTag] = useState("")

    const updateLinks = async (e) => {
        setLoading(true)
        await updateDoc(doc(db, "sites", props.site.url), {
            "insta": insta || "",
            "twitter": twitter || "",
            "linkedin": linkedin || "",
            "google_tag": google_tag || ""
        })
        setLoading(false)
    }

    useEffect(() => {
        setInsta(props.site.insta)
        setTwitter(props.site.twitter)
        setLinkedin(props.site.linkedin)
        setGoogleTag(props.site.google_tag)
    }, [props.site]);

    return (
        <>
            {loading == true ? <div id="loading-content">
                <img src={gif} />
            </div> :
                <form noValidate onSubmit={updateLinks}>
                    <div className="space-y-12 sm:space-y-16">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Social Links</h2>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-600">
                                Let your users connect with you beyond your website.
                            </p>

                            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label htmlFor="instagram" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Instagram
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="instagram"
                                            id="instagram"
                                            value={insta}
                                            onChange={(e) => setInsta(e.target.value)}
                                            defaultValue={props.site.insta}
                                            placeholder="https://instagram.com/profile"
                                            autoComplete="given-name"
                                            className="block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label htmlFor="twitter" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Twitter
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            type="text"
                                            name="twitter"
                                            id="twitter"
                                            value={twitter}
                                            onChange={(e) => setTwitter(e.target.value)}
                                            defaultValue={props.site.twitter}
                                            placeholder="https://twitter.com/profile"
                                            autoComplete="family-name"
                                            className="block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label htmlFor="linkedin" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        LinkedIn
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            id="linkedin"
                                            name="linkedin"
                                            type="text"
                                            value={linkedin}
                                            onChange={(e) => setLinkedin(e.target.value)}
                                            defaultValue={props.site.linkedin}
                                            placeholder="https://linkedin.com/profile"
                                            autoComplete="linkedin"
                                            className="block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-md sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                                    <label htmlFor="google_tag" className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                                        Google Tag
                                    </label>
                                    <div className="mt-2 sm:col-span-2 sm:mt-0">
                                        <input
                                            id="google_tag"
                                            name="google_tag"
                                            type="text"
                                            value={google_tag}
                                            onChange={(e) => setGoogleTag(e.target.value)}
                                            defaultValue={props.site.google_tag}
                                            placeholder="AW-129017307"
                                            autoComplete="google_tag"
                                            className="block pl-3 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-md sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="mt-7 float-right">
                                <button
                                    type="submit"
                                    className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                >
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            }
        </>
    )
}

function Payments(props) {

    const [loading, setLoading] = useState(false)
    const [balanceLoading, setBalanceLoading] = useState(false)
    const [statusLoading, setStatusLoading] = useState(false)
    const [status, setStatus] = useState("")
    const [error, setError] = useState("")
    const [balance, setBalance] = useState(0)
    const [pending, setPending] = useState(0)

    const onboard = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let site = props.site

        var raw = JSON.stringify({
            "site": site.url
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setLoading(true)
        fetch("https://app-7b3yqs3oka-uc.a.run.app/onboard-user", requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                window.open(result.url)
            })
            .catch(error => {
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    const onboardRefresh = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let site = props.site

        var raw = JSON.stringify({
            "site": site.url,
            "accountID": site.accountID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setLoading(true)
        fetch("https://app-7b3yqs3oka-uc.a.run.app/onboard-user/refresh", requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                window.open(result.url)
            })
            .catch(error => {
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    const getStatus = async () => {
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let site = props.site

            var raw = JSON.stringify({
                "accountID": site.accountID
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            setStatusLoading(true)
            fetch("https://app-7b3yqs3oka-uc.a.run.app/get-status", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setStatusLoading(false)
                    setStatus(result.status)
                })
                .catch(error => {
                    setError(error.message)
                    setStatusLoading(false)
                    setTimeout(
                        function () {
                            setError('')
                        }.bind(this), 5000);
                });
    }

    const getBalance = async () => {
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            let site = props.site

            var raw = JSON.stringify({
                "accountID": site.accountID
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            setBalanceLoading(true)
            fetch("https://app-7b3yqs3oka-uc.a.run.app/get-balance", requestOptions)
                .then(response => response.json())
                .then(result => {
                    setBalanceLoading(false)
                    setBalance(result.available)
                    setPending(result.pending)
                })
                .catch(error => {
                    setError(error.message)
                    setBalanceLoading(false)
                    setTimeout(
                        function () {
                            setError('')
                        }.bind(this), 5000);
                });
    }

    useEffect(() => {
        if (props.site.accountID != null) {
            async function initialize() {
                await getStatus();
                await getBalance();
            }
            initialize();
        }
    }, []);

    return (
        <>
            {props.site != null && props.site.accountID != null ?

                <>
                    {
                        statusLoading == true ? <div id="loading-content">
                            <img src={gif} />
                        </div> : <> {
                            status == "Completed" ?
                                <>{balanceLoading == true ? <div id="loading-content">
                                    <img src={gif} />
                                </div> :
                                    <div>
                                        <div className="px-4 sm:px-0 border-b border-gray-300 pb-6">
                                            <h3 className="text-base font-semibold leading-7 text-gray-900">Payments</h3>
                                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">You need to get paid for your work.</p>
                                        </div>
                                        <div className="lg:col-start-3 lg:row-end-1 mt-6">
                                            <h2 className="sr-only">Summary</h2>
                                            <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
                                                <dl className="flex flex-wrap">
                                                    <div className="flex-auto pl-6 py-6">
                                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
                                                        <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">${stripify(balance)}</dd>
                                                    </div>
                                                    <div className="flex-none self-end px-6 py-6">
                                                        <dt className="sr-only">Status</dt>
                                                        <dd className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                            Available
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>

                                        <div className="lg:col-start-3 lg:row-end-1 mt-6">
                                            <h2 className="sr-only">Summary</h2>
                                            <div className="rounded-lg bg-white shadow-sm ring-1 ring-gray-900/5">
                                                <dl className="flex flex-wrap">
                                                    <div className="flex-auto pl-6 py-6">
                                                        <dt className="text-sm font-semibold leading-6 text-gray-900">Amount</dt>
                                                        <dd className="mt-1 text-base font-semibold leading-6 text-gray-900">${stripify(pending)}</dd>
                                                    </div>
                                                    <div className="flex-none self-end px-6 py-6">
                                                        <dt className="sr-only">Status</dt>
                                                        <dd className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/20">
                                                            Pending
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>

                                    </div>} </> : <>
                                    {
                                        loading == true ? <div id="loading-content">
                                            <img src={gif} />
                                        </div> :
                                            <div>
                                                <div className="px-4 sm:px-0 border-b border-gray-300 pb-6">
                                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Payments</h3>
                                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">You need to get paid for your work.</p>
                                                </div>
                                                <div className="border-l-4 border-red-400 bg-red-50 p-4 mt-6">
                                                    <div className="flex">
                                                        <div className="flex-shrink-0">
                                                            <ExclamationTriangleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                                        </div>
                                                        <div className="ml-3">
                                                            <p className="text-sm text-red-700">
                                                                You have not finished setting up your payments account.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-white shadow sm:rounded-lg mt-6">
                                                    <div className="px-4 py-5 sm:p-6">
                                                        <div className="sm:flex sm:items-start sm:justify-between">
                                                            <div>
                                                                <h3 className="text-base font-semibold leading-6 text-gray-900">Get paid!</h3>
                                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                                    <p>
                                                                        Please finish setting up your account on Stripe. <b>Click the button to finish setting up.</b>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                                    onClick={onboardRefresh}
                                                                >
                                                                    Finish account setup
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {error != "" ?
                                                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
                                                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                                            </svg>
                                                            <span class="sr-only">Error icon</span>
                                                        </div>
                                                        <div class="ms-3 text-sm font-normal">{error}</div>
                                                    </div> : <></>}
                                            </div>
                                    }
                                </>
                        }</>
                    }
                </>

                :

                <>
                    {
                        loading == true ? <div id="loading-content">
                            <img src={gif} />
                        </div> :
                            <div>
                                <div className="px-4 sm:px-0 border-b border-gray-300 pb-6">
                                    <h3 className="text-base font-semibold leading-7 text-gray-900">Payments</h3>
                                    <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">You need to get paid for your work.</p>
                                </div>
                                <div className="bg-white shadow sm:rounded-lg mt-6">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="sm:flex sm:items-start sm:justify-between">
                                            <div>
                                                <h3 className="text-base font-semibold leading-6 text-gray-900">Get paid!</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        We have partnered with Stripe to help accept payments on your behalf.<br />
                                                        <b>Clicking the button will take you to Stripe.</b> <i>Also make sure to have popups enabled.</i>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                                                <button
                                                    type="button"
                                                    className="inline-flex items-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                                    onClick={onboard}
                                                >
                                                    Set up account
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {error != "" ?
                                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
                                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                            </svg>
                                            <span class="sr-only">Error icon</span>
                                        </div>
                                        <div class="ms-3 text-sm font-normal">{error}</div>
                                    </div> : <></>}
                            </div>
                    }
                </>
            }
        </>
    )
}

const tiers = [
    {
        name: 'Founder',
        id: 0,
        href: '#',
        price: { monthly: '$9' },
        description: 'Good for self-employed and just getting started.',
        features: ['Unlimited products', 'Unlimited contacts', 'Premium analytics', '1-hour support response time'],
    },
    // {
    //     name: 'Small Business',
    //     id: 1,
    //     href: '#',
    //     price: { monthly: '$15' },
    //     description: 'Perfect for small / medium sized businesses.',
    //     features: [
    //         '25 products',
    //         'Up to 10,000 subscribers',
    //         'Advanced analytics',
    //         '24-hour support response time',
    //         'Marketing automations',
    //     ],
    // },
    // {
    //     name: 'Enterprise',
    //     id: 2,
    //     href: '#',
    //     price: { monthly: '$39' },
    //     description: 'For even the biggest enterprise companies.',
    //     features: [
    //         'Unlimited products',
    //         'Unlimited subscribers',
    //         'Advanced analytics',
    //         '1-hour, dedicated support response time',
    //         'Marketing automations',
    //         'Custom reporting tools',
    //     ],
    // },
]

function Billing(props) {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("")

    const createCustomer = async (i) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "name": auth.currentUser.displayName,
            "email": auth.currentUser.email,
            "site": props.site.url
          });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        setLoading(true)
        fetch("https://app-7b3yqs3oka-uc.a.run.app/create-customer", requestOptions)
            .then(response => response.json())
            .then(result => {
                var raw2 = JSON.stringify({
                    "price": i,
                    "customerID": result.customer
                });
        
                var requestOptions2 = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw2,
                    redirect: 'follow'
                };
                fetch("https://app-7b3yqs3oka-uc.a.run.app/create-subscription", requestOptions2)
                    .then(response => response.json())
                    .then(result => {
                        setLoading(false)
                        window.open(result.url)
                    }).catch(error => {
                        setError(error.message)
                        setLoading(false)
                        setTimeout(
                            function () {
                                setError('')
                            }.bind(this), 5000);
                    });
            })
            .catch(error => {
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    const managePortal = async () => {
        console.log('here')
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "customerID": props.site.customerID
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch("https://app-7b3yqs3oka-uc.a.run.app/update-subscription", requestOptions)
            .then(response => response.json())
            .then(result => {
                setLoading(false)
                window.open(result.url)
            }).catch(error => {
                setError(error.message)
                setLoading(false)
                setTimeout(
                    function () {
                        setError('')
                    }.bind(this), 5000);
            });
    }

    return (
        <div>
            <div className="px-4 sm:px-0 border-b border-gray-300 pb-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Billing</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">We appreciate your trust in our services.</p>
            </div>
            {props.site.customerID != null ?
                <>
                    <div className="mt-6 bg-white shadow sm:rounded-lg">
                        <div className="px-4 py-5 sm:p-6">
                            <div className="sm:flex sm:items-start sm:justify-between">
                                <div>
                                    <h3 className="text-base font-semibold leading-6 text-gray-900">Manage subscription</h3>
                                    <div className="mt-2 max-w-xl text-sm text-gray-500">
                                        <p>
                                            We appreciate your continued support as a valued member of our community.
                                        </p>
                                    </div>
                                </div>
                                <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                                    <button
                                        type="button"
                                        className="inline-flex items-center rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                                        onClick={managePortal}
                                    >
                                       Manage my subscription
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </> :

                <div className="mt-6">
                    <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                    You have not set up billing. Please pick a plan below.
                                </p>
                            </div>
                        </div>
                    </div>
                    {loading == true ? <div className="mt-20" id="loading-content">
                        <img src={gif} />
                    </div> :
                        <div className="mt-20 flow-root">
                            <div className="isolate -mt-16 grid max-w-sm grid-cols-1 gap-y-16 divide-y divide-gray-300 sm:mx-auto lg:-mx-8 lg:mt-0 lg:max-w-none lg:grid-cols-2 lg:divide-x lg:divide-y-0 xl:-mx-4">
                                {tiers.map((tier) => (
                                    <div key={tier.id} className="pt-16 lg:px-8 lg:pt-0 xl:px-7">
                                        <h3 id={tier.id} className="text-base font-semibold leading-7 text-gray-900">
                                            {tier.name}
                                        </h3>
                                        <p className="mt-6 flex items-baseline gap-x-1">
                                            <span className="text-5xl font-bold tracking-tight text-gray-900">{tier.price.monthly}</span>
                                            <span className="text-sm font-semibold leading-6 text-gray-600">/month</span>
                                        </p>
                                        <a
                                            onClick={() => createCustomer(tier.id)}
                                            aria-describedby={tier.id}
                                            className="mt-10 block rounded-md bg-green-600 px-3 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                        >
                                            Start Free Trial
                                        </a>
                                        <p className="mt-10 text-sm font-semibold leading-6 text-gray-900">{tier.description}</p>
                                        <ul role="list" className="mt-6 space-y-3 text-sm leading-6 text-gray-600">
                                            {tier.features.map((feature) => (
                                                <li key={feature} className="flex gap-x-3">
                                                    <CheckCircleIcon className="h-6 w-5 flex-none text-green-600" aria-hidden="true" />
                                                    {feature}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                    {error != "" ?
                        <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
                            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                </svg>
                                <span class="sr-only">Error icon</span>
                            </div>
                            <div class="ms-3 text-sm font-normal">{error}</div>
                        </div> : <></>}
                </div>

            }
        </div>
    )
}

function Domains(props) {

    // let current = props.steps.find(step => step.status == "current");

    // const prevStep = () => {

    // }

    // const nextStep = () => {

    // }

    return (
        <div>
            <div className="px-4 sm:px-0 border-b border-gray-300 pb-6">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Domains</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Boost your brand by connecting your own domain.</p>
            </div>
            <p className="mt-10 max-w-2xl text-md leading-6 text-gray-500">Please email <Link to="mailto:admin@sudoservices.io" className="font-semibold text-green-600 hover:text-green-500">admin@sudoservices.io</Link>, and we will help you set up your custom domain free of charge.</p>
        </div>
            /* <nav aria-label="Progress" className="mt-6">
                <ol role="list" className="space-y-4 md:flex md:space-x-8 md:space-y-0">
                    {props.steps.map((step) => (
                        <li key={step.name} className="md:flex-1">
                            {step.status === 'complete' ? (
                                <a
                                    onClick={() => step(step.name)}
                                    className="group flex flex-col border-l-4 border-green-600 py-2 pl-4 hover:border-green-800 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-green-600 group-hover:text-green-800">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : step.status === 'current' ? (
                                <a
                                    href={step.href}
                                    className="flex flex-col border-l-4 border-green-600 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                    aria-current="step"
                                >
                                    <span className="text-sm font-medium text-green-600">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            ) : (
                                <a
                                    href={step.href}
                                    className="group flex flex-col border-l-4 border-gray-200 py-2 pl-4 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
                                >
                                    <span className="text-sm font-medium text-gray-500 group-hover:text-gray-700">{step.id}</span>
                                    <span className="text-sm font-medium">{step.name}</span>
                                </a>
                            )}
                        </li>
                    ))}
                </ol>
            </nav>
            <div className="mt-10">
                {current.name != "Buy Domain" ?
                    <Link onClick={() => prevStep()} className="inline-flex w-full items-center gap-x-1.5 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600 justify-start text-blue-600 group-hover:text-blue-800">
                        <ArrowLeftIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                        Go Back
                    </Link> : <></>
                }
                <h2 className="mt-4 text-sm font-semibold leading-7 text-gray-900">{current.name}</h2>


                <ul class="space-y-1 text-gray-800 list-disc list-inside">
                    <li>Go to your domain provider (the company you bought your domain from)</li>
                    <li>Login and then navigate to your domain name.</li>
                    <li>Go to <b>DNS Settings</b>, and then <b>Custom Records</b></li>
                    <li>Click <b>Add record</b></li>
                    <li>Enter the <b>host name</b>. If you want to point to <b>yourdomain.com</b>, usually you will leave the host blank or put an @.</li>
                    <li>For your <b>data section</b>, enter in <b>yourwebsite.sudoservices.io</b></li>
                    <li><b>Save the record</b></li>
                </ul>

                <p className="mt-5 max-w-2xl text-sm leading-6 text-gray-500">For more specific information about how to add a CNAME record, you can Google, "Adding CNAME Record for DOMAIN PROVIDER"</p>

                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">*** If you need help with any step, email <Link to="mailto:haroonmohamedali@gmail.com" className="font-semibold text-green-600 hover:text-green-500">haroonmohamedali@gmail.com</Link>, and I will help you set up your custom domain free of charge. ***</p>

                {current.name != "Verify" ?
                    <div class="mt-7 float-right">
                        <button
                            type="button"
                            onClick={() => nextStep()}
                            className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                        >
                            Next
                        </button>
                    </div> : <></>}
            </div>
        </div> */
    )
}

export default Settings;