import React from 'react';
import '../styles/Login.css';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import tooler from "../images/sudoservices-transparent.png"
import { CheckCircleIcon } from '@heroicons/react/20/solid';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', message: '', type: "warning" };

        this.handleReset = this.handleReset.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleReset(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            sendPasswordResetEmail(this.props.auth, this.state.email)
                .then(() => {
                    this.setState({
                        message: "Password reset email sent!",
                        type: 'success'
                    })
                    setTimeout(
                        function() {
                            this.setState({
                                message: ''
                            })
                        }.bind(this), 5000);
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    this.setState({
                        message: errorMessage,
                        type: 'warning'
                    })
                    setTimeout(
                        function() {
                            this.setState({
                                messsage: ''
                            })
                        }.bind(this), 5000);
                });
        }

        e.target.classList.add('was-validated');
    }

    handleChange(e) {
        const id = e.target.id;
        this.setState({
            [id]: e.target.value
        })
    }

    render() {
        return (
            <div>
                <div className="flex min-h-full flex-1" style={{"min-height": "100vh"}}>
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-10 w-auto"
                  src={tooler}
                  alt="SudoServices"
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Reset Password
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  <Link to="/login" className="font-semibold text-green-600 hover:text-green-500">
                    Back to login
                  </Link>
                </p>
              </div>
  
              <div className="mt-10">
                <div>
                  <form className="space-y-6" onSubmit={this.handleReset}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={this.state.email}
                          onChange={this.handleChange}
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                        />
                      </div>
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      >
                        Reset Password
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block bg-mesher-gradient ml-20">
          </div>
        </div>
                {/* <div className='Logo-Login'>
                    <img src="https://app.sunalabs.com/sunalabs.png" />
                </div>
                <div className="Login-Container">
                    <div className="Login">
                        <Row>
                            <Col sm={9}>
                        <h3>Forgot Password</h3>
                        <form id="sign-in" noValidate="" onSubmit={this.handleReset}>

                            <div class="mb-3">
                                <label class="form-label" for="email">Email</label>
                                <input class="form-control" id="email" type="email" placeholder="Email" required value={this.state.email} onChange={this.handleChange} />
                                <div class="invalid-feedback">
                                    Please provide a valid email.
                                </div>
                            </div>


                            <div class="d-grid">
                                <button class="btn btn-primary btn-lg" id="submit-message">Submit</button>
                            </div>

                        </form>
                        </Col>
                        </Row>
                    </div>
                </div> */}

                {this.state.message != "" ?
                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
                        {this.state.type == "warning" ? <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                            </svg>
                            <span class="sr-only">Error icon</span>
                        </div> : 
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                            <CheckCircleIcon />
                        </div>}
                        <div class="ms-3 text-sm font-normal">{this.state.message}</div>
                    </div> : <></> }
            </div>
        );
    }
}

export default ForgotPassword;