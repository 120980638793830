export class Contact {
    constructor(site, name, email, lastMessage, unread, doc) {
        this.site = site;
        this.name = name;
        this.email = email;
        this.lastMessage = lastMessage;
        this.unread = unread;
        this.doc = doc;
    }

    from_json(site, email, data, doc) {
        return new Contact(site, data.name, email, data.lastMessage, data.unread, doc);
    }

    to_json() {
        return {
            site: this.site,
            name: this.name,
            email: this.email,
        }
    }
}