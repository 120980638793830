import { db, imageUpload } from "../firebaseConfig";
import { doc, setDoc, updateDoc } from "firebase/firestore";

export class Service {
    constructor(site, name, pricing, description, requirements, gallery, doc, sessions, revenue, orders) {
        this.site = site;
        this.name = name; // name
        this.pricing = pricing; // pricing class
        this.description = description; // just text
        this.requirements = requirements; // list of questions class
        this.gallery = gallery; //gallery class (first image is the service icon)
        this.sessions = sessions;
        this.revenue = revenue;
        this.orders = orders;
        this.doc = doc;

        this[name] = name;
        this[pricing] = pricing;
        this[description] = description;
        this[requirements] = requirements;
        this[gallery] = gallery;
        this[doc] = doc;
    }

    async createService() {
        const url_lst = await this.gallery.upload();
        const questions = [];
        for(let i = 0; i < this.requirements.length; i++) {
            questions.push(this.requirements[i].to_json())
        }
        const good = this.pricing.good.to_json();
        const better = this.pricing.better.to_json();
        const best = this.pricing.best.to_json();
        await setDoc(doc(db, `sites/${this.site}/services/${this.name}`), {
            name: this.name,
            description: this.description,
            images: url_lst,
            yt_videos: this.gallery.yt_videos,
            questions: questions,
            good: good,
            better: better,
            best: best,
            sessions: 0,
            revenue: 0,
            orders: 0
        })
    }

    async editService() {
        // const url_lst = await this.gallery.upload();
        const questions = [];
        for(let i = 0; i < this.requirements.length; i++) {
            questions.push(this.requirements[i].to_json())
        }
        const good = this.pricing.good.to_json();
        const better = this.pricing.better.to_json();
        const best = this.pricing.best.to_json();
        await updateDoc(doc(db, `sites/${this.site}/services/${this.name}`), {
            description: this.description,
            // images: url_lst,
            // yt_videos: this.gallery.yt_videos,
            questions: questions,
            good: good,
            better: better,
            best: best,
        })
    }
}

export class Pricing {
    constructor(good, better, best) {
        this.good = good;
        this.better = better;
        this.best = best;

        this[good] = good;
        this[better] = better;
        this[best] = best;
    }

    from_json() {

    }
}

export class Package {
    constructor(name, details, delivery, revisions, items_included, items_not_included, price, action) {
        this.name = name;
        this.details = details; // short description
        this.delivery = delivery; // delivery time in days
        this.revisions = revisions; // number of revisions
        this.items_included = items_included; // all the items included. just a list
        this.items_not_included = items_not_included;
        this.price = price;
        this.action = action; // purchase or contact

        this[name] = name;
        this[details] = details; // short description
        this[delivery] = delivery; // delivery time in days
        this[revisions] = revisions; // number of revisions
        this[items_included] = items_included; // all the items included[ just a list
        this[items_not_included] = items_not_included;
        this[price] = price;
        this[action] = action; // purchase or contact
    }

    to_json() {
        return {
            name: this.name,
            details: this.details,
            delivery: this.delivery,
            revisions: this.revisions,
            items_included: this.items_included,
            items_not_included: this.items_not_included,
            price: this.price,
            action: this.action
        }
    }

    from_json(data) {
        return new Package(data.name, data.details, data.delivery, data.revisions, data.items_included, data.items_not_included, data.price, data.action)
    }
}

export class Question {
    constructor(question, response_type, required) {
        this.question = question;
        this.response_type = response_type;
        this.required = required;

        this[question] = question;
        this[response_type] = response_type;
        this[required] = required;
    }

    to_json() {
        return {
            question: this.question,
            response_type: this.response_type,
            required: this.required
        }
    }

    from_json(data) {
        return new Question(data.question, data.response_type, data.required);
    }
}

export class Gallery {
    constructor(images, yt_videos) {
        this.images = images;
        this.yt_videos = yt_videos;

        this[images] = images;
        this[yt_videos] = yt_videos;
    }

    async upload() {
        const urls = []
        for(let i = 0; i < this.images.length; i++) {
            const res = await imageUpload(this.images[i], "service-images")
            if(res.status == "success") {
                urls.push(res.url);
            } else {
                return [];
            }
        }

        return urls;
    }
}