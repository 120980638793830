import { initializeApp } from 'firebase/app';
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { uuidv4 } from '@firebase/util';

const firebaseConfig = {
    apiKey: "AIzaSyDlcllafvFNXhMsYDC8RTzcu-vZkv_yIkM",
    authDomain: "sunalabs.firebaseapp.com",
    projectId: "sunalabs",
    storageBucket: "sunalabs.appspot.com",
    messagingSenderId: "215244864025",
    appId: "1:215244864025:web:ebb4181630b9de684d0888",
    measurementId: "G-V6NSGBPYZ9"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);

export async function imageUpload(file, bucket) {
    if (!file) {
        alert("Select a file")
    }

    try {
        const filename = uuidv4() + "." + file.name.split('.').pop();
        const storageRef = ref(storage, `/${bucket}/${filename}`);
        await uploadBytesResumable(storageRef, file);
        const url = await getDownloadURL(storageRef);
        return {"status": "success", "url": url};
    } catch (e) {
        return {"status": "failed", "message": e.message};
    }
}