import React, { useState, useEffect } from "react";
import Nav2 from "../components/Nav2";
import { ListGroup, Row } from "react-bootstrap";
import { createContactTests, getContacts } from "../services/firebase_service";
import values from "../values";
import MainLayout from "../layouts/MainLayout";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { classNames } from "../consts";

const pages = [
    { name: 'Contacts', href: '/contacts', current: true },
]

function Contacts() {
    return (
        <MainLayout current="Contacts" content={<ContactComponent />} />
    )
}

function ContactComponent() {

    const [afterThis, setAfterThis] = useState(null);
    const [beforeThis, setBeforeThis] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [page, setPage] = useState(0)
    const [contacts, setContacts] = useState([])

    const getInitials = (name) => {
        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

        let initials = [...name.matchAll(rgx)] || [];

        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
        ).toUpperCase();
        return initials
    }

    const setDocs = (contacts, page) => {
        const first_index = 0;
        const last_index = contacts.length - 1;

        if (last_index >= 0) {
            setAfterThis(contacts[last_index].doc)
        }
        if (page == 0) {
            setBeforeThis(contacts[first_index].doc)
        } else {
            setBeforeThis(firstDoc)
        }
        setFirstDoc(contacts[first_index].doc)
    }


    useEffect(() => {
        async function getElements() {
            let contacts = await getContacts(values.site, afterThis);
            setContacts(contacts)
            setDocs(contacts, 0)
        }
        getElements();
    }, []);

    async function nextPage() {
        if (contacts.length == 10) {
            let new_contacts = await getContacts(values.site, afterThis, null);
            if (new_contacts.length > 0) {
                const newPage = page + 1;
                setPage(newPage)
                setDocs(new_contacts, newPage);
                setContacts(new_contacts);
            }
        }
    }

    async function prevPage() {
        if (page > 0) {
            let new_contacts = await getContacts(values.site, beforeThis, true);
            if (new_contacts.length > 0) {
                const newPage = page - 1;
                setPage(newPage)
                setDocs(new_contacts, newPage);
                setContacts(new_contacts);
            }
        }
    }

    return (
        <div className="Page">
            <div className="Contacts bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Contacts
                        </h2>
                    </div>
                    <br />
                    {contacts.length == 0 ? <h5 style={{ "fontWeight": "300" }} class="mb-0">
                        No Contacts
                    </h5> : <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                Name
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Unread Messages
                                            </th>
                                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                Last Message
                                            </th>
                                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200">
                                        {contacts.map((contact) => (
                                            <tr key={contact.email}>
                                                <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                    <div className="flex items-center">
                                                        <div className="h-11 w-11 flex-shrink-0">
                                                            <span className="inline-flex h-11 w-11 items-center justify-center rounded-full bg-gray-500">
                                                                <span className="font-medium leading-none text-white">{getInitials(contact.name)}</span>
                                                            </span>
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="font-medium text-gray-900">{contact.name}</div>
                                                            <div className="mt-1 text-gray-500">{contact.email}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <span className={
                                                        classNames(
                                                            contact.unread == 0 ? "bg-green-50 text-green-700 ring-green-600/20" : "bg-red-50 text-red-700 ring-red-600/20",
                                                            "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset"
                                                        )
                                                    }>
                                                        {contact.unread}
                                                    </span>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                    <div className="text-gray-900">{contact.lastMessage.toDate().toLocaleDateString('en-US', { year: "numeric", month: "short", day: "numeric" })}</div>
                                                </td>
                                                <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                    <Link to={"/contacts/" + contact.email} className="text-green-600 hover:text-green-900">
                                                        Manage Contact<span className="sr-only">, {contact.name}</span>
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                            {page != 0 &&
                                <div className="-mt-px flex w-0 flex-1">
                                    <a
                                        onClick={prevPage}
                                        className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                    >
                                        <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                        Previous
                                    </a>
                                </div>
                            }
                            <div className="-mt-px flex w-0 flex-1 justify-end">
                                <a
                                    onClick={nextPage}
                                    className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                >
                                    Next
                                    <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </a>
                            </div>
                        </nav>
                    </div>
                    }
                    <br />
                    <br />
                </div>
            </div>
        </div>
    );
}

export default Contacts;