export class Order {
    constructor(id, site, service, name, email, responses, time, pkg, payment, status, doc, fulfillment) {
        this.id = id;
        this.site = site;
        this.service = service;
        this.name = name;
        this.email = email;
        this.responses = responses;
        this.time = time;
        this.pkg = pkg;
        this.payment = payment;
        this.status = status;
        this.doc = doc;
        this.fulfillment = fulfillment;
    }

    from_json(id, data, doc) {
        return new Order(id, data.site, data.service, data.name, data.email, data.responses, data.time, data.package, data.payment, data.status, doc, data.fulfillment);
    }

    to_json() {
        return {
            id: this.id,
            site: this.site,
            service: this.service,
            name: this.name,
            email: this.email,
            responses: this.responses,
            time: this.time,
            pkg: this.pkg,
            payment: this.payment,
            status: this.status,
            fulfillment: this.fulfillment
        }
    }
}