import { useState } from "react"
import { sendQuote } from "../services/firebase_service"
import values from "../values"
import gif from "../loading.gif"
import { classNames } from "../consts"
import { imageUpload } from "../firebaseConfig"

export default function SentMail(props) {

    const [price, setPrice] = useState("")
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)

    const SendQuote = async () => {
        setLoading(true)
        try {
            let res = null;
            if(file != null) {
                res = await imageUpload(file, "quotes");
            }
            await sendQuote(values.site, props.quoteNo, price, props.email, res == null ? "" : res.url)
            setLoading(false)
            props.close();
        } catch (e) {
            setLoading(false)
        }
    }

    return (
        <div>
            {loading == true ?
                <div id="loading-content">
                    <img src={gif} />
                </div> :
                <>
                    <div className="flex items-center justify-between">
                        <h2 className="text-sm font-medium leading-6 text-gray-900">Quote Customer</h2>
                    </div>

                    <div>
                        <div className="mt-2">
                            <input
                                value={price}
                                type="number"
                                placeholder="Price"
                                name="price"
                                id="price"
                                className="block w-full rounded-md border-0 py-1.5 px-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                aria-describedby="email-description"
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>
                        <p className="mt-2 text-sm text-gray-500" id="email-description">
                            This is the total price. This is what we will charge the customer.
                        </p>
                        <div class="mt-5 mb-3">
                            <label
                                for="formFile"
                                class="text-sm font-medium leading-6 block text-gray-900 mb-2"
                                >Upload Quote*</label>
                            <input className="text-sm text-stone-500
                                file:mr-5 file:py-1 file:px-3 file:border-[1px]
                                file:text-xs file:font-medium
                                file:bg-stone-50 file:text-stone-700
                                hover:file:cursor-pointer hover:file:bg-green-50
                                hover:file:text-green-700" id="siteicon" type="file" accept="application/pdf" onChange={(e) => setFile(e.target.files[0])} />
                            <p className="mt-2 text-sm text-gray-500" id="email-description">
                                PDF only
                            </p>
                        </div>
                    </div>

                    <div className="mt-5 sm:mt-6">
                        <button
                            type="button"
                            className={
                                classNames(
                                    price == "" ? "bg-gray-400 focus-visible:outline-gray-600" : "bg-green-600 hover:bg-green-500 focus-visible:outline-green-600",
                                    "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                                )
                            }
                            onClick={() => { SendQuote() }}
                        >
                            Send Quote
                        </button>
                    </div>
                </>
            }
        </div>
    )
}