import { TrashIcon, XMarkIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import Tooltip from 'react-bootstrap/Tooltip';

const FlowStep = props => {

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Delete Question
        </Tooltip>
    );

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedQuestion = { ...props.question, [name]: value };
        props.onQuestionChange(updatedQuestion)
    }

    return (
        <fieldset>
            <legend className="flex w-full text-sm font-medium leading-6 text-gray-900 justify-between">
                Question {props.step}
                <TrashIcon className="h-4 w-4 text-gray-800" style={{"cursor": "pointer"}} onClick={props.remove} />
            </legend>
            <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                <div>
                    <label htmlFor="question" className="sr-only">
                        Question
                    </label>
                    <input
                        type="text"
                        name="question"
                        id="question"
                        value={props.question.question}
                        onChange={handleChange}
                        className="relative block w-full rounded-none rounded-t-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-3"
                        placeholder="e.g. What is the primary purpose of this website?"
                    />
                </div>
                <div className="flex -space-x-px">
                    <div className="w-1/2 min-w-0 flex-1">
                        <label htmlFor="required" className="sr-only">
                            Required
                        </label>
                        <select
                            id="required"
                            name="required"
                            onChange={handleChange}
                            value={props.question.required}
                            className="relative block w-full rounded-none rounded-bl-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-3"
                            required
                        >
                            <option value='' disabled selected>Required?</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className="min-w-0 flex-1">
                        <label htmlFor="card-cvc" className="sr-only">
                            Response Type
                        </label>
                        <select
                            id="response"
                            name="response_type"
                            onChange={handleChange}
                            value={props.question.response_type}
                            className="relative block w-full rounded-none rounded-br-md border-0 bg-transparent py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-3 mr-3"
                        >
                            <option value='' disabled selected>Response?</option>
                            <option value="Free Response">Free Response</option>
                            <option value="File">File</option>
                        </select>
                    </div>
                </div>
            </div>
        </fieldset>
    );
}

export default FlowStep;