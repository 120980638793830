import React, { useState, useEffect } from "react";
import Nav2 from "../components/Nav2";
import { Link } from "react-router-dom";
import { getServices } from "../services/firebase_service";
import values from "../values";
import MainLayout from "../layouts/MainLayout";
import nothumbnail from "../no-thumbnail.png"
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";

const pages = [
    { name: 'Services', href: '/services', current: true },
]

function Services() {

    return (
        <MainLayout current="Services" content={<ServicesCompontent />} />
    );
}

function ServicesCompontent() {

    const [afterThis, setAfterThis] = useState(null);
    const [beforeThis, setBeforeThis] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [page, setPage] = useState(0)
    const [services, setServices] = useState([])

    const setDocs = (services, page) => {
        const first_index = 0;
        const last_index = services.length - 1;

        if (last_index >= 0) {
            setAfterThis(services[last_index].doc)
        }
        if (page == 0) {
            setBeforeThis(services[first_index].doc)
        } else {
            setBeforeThis(firstDoc)
        }
        setFirstDoc(services[first_index].doc)
    }

    useEffect(() => {
        async function getElements() {
            let services = await getServices(values.site, afterThis);
            setServices(services);
            setDocs(services, 0)
        }

        getElements();
    }, []);

    async function nextPage() {
        if (services.length == 10) {
            let new_services = await getServices(values.site, afterThis, null);
            if (new_services.length > 0) {
                const newPage = page + 1;
                setPage(newPage)
                setDocs(new_services, newPage);
                setServices(new_services);
            }
        }
    }

    async function prevPage() {
        if (page > 0) {
            let new_services = await getServices(values.site, beforeThis, true);
            if (new_services.length > 0) {
                const newPage = page - 1;
                setPage(newPage)
                setDocs(new_services, newPage);
                setServices(new_services);
            }
        }
    }

    return (
        <div className="Page">
            <div className="Services bg-base-1">
                <div class="container py-5 px-5">
                    <div class="row">
                        <div class="col-12 col-lg">
                            <Nav2 pages={pages} />
                        </div>
                    </div>
                    <br />
                    <div className="md:flex md:items-center md:justify-between">
                        <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                            Services
                        </h2>
                        <Link to="/create">
                            <button
                                type="button"
                                className="inline-flex items-center justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                Add Service
                            </button>
                        </Link>
                    </div>
                    <br />
                    {services.length == 0 ? <h5 style={{ "fontWeight": "300" }} class="mb-0">
                        No Services
                    </h5> :
                        <div>
                            <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-3 xl:gap-x-8">
                                {services.map((service, index) => (
                                    <Link to={"/services/" + service.name} className="linkNoStyle">
                                        <li key={index} className="relative">
                                            <div className="group aspect-h-7 aspect-w-10 flex w-full overflow-hidden rounded-lg border focus-within:ring-2 focus-within:ring-green-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-300 justify-center">
                                                <img src={service.gallery.images.length != 0 ? service.gallery.images[0] : nothumbnail} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                                                <button type="button" className="absolute inset-0 focus:outline-none">
                                                    <span className="sr-only">View details for {service.name}</span>
                                                </button>
                                            </div>
                                            <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{service.name}</p>
                                            <p className="pointer-events-none block text-sm font-medium text-gray-500">${service.pricing.good.price}</p>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                            <nav className="mt-10 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
                                {page != 0 &&
                                    <div className="-mt-px flex w-0 flex-1">
                                        <a
                                            onClick={prevPage}
                                            className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                        >
                                            <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            Previous
                                        </a>
                                    </div>
                                }
                                <div className="-mt-px flex w-0 flex-1 justify-end">
                                    <a
                                        onClick={nextPage}
                                        className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer"
                                    >
                                        Next
                                        <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </a>
                                </div>
                            </nav>
                        </div>
                    }
                    <br />
                    <br />
                </div>
            </div>
        </div>
    )
}

export default Services;