import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db, imageUpload } from "../firebaseConfig";


export class Site {
    constructor(name, url, icon, colors, slogan, revenue, orders, sessions, accountID, insta, twitter, linkedin, customerID, google_tag) {
        this.name = name;
        this.url = url;
        this.icon = icon;
        this.colors = colors;
        this.slogan = slogan;
        this.revenue = revenue;
        this.orders = orders;
        this.sessions = sessions;
        this.accountID = accountID;
        this.insta = insta;
        this.twitter = twitter;
        this.linkedin = linkedin;
        this.customerID = customerID;
        this.google_tag = google_tag;

        this[name] = name;
        this[url] = url;
        this[icon] = icon;
        this[colors] = colors;
        this[slogan] = slogan;
        this[revenue] = revenue;
        this[orders] = orders;
        this[sessions] = sessions;
        this[accountID] = accountID;
        this[insta] = insta;
        this[twitter] = twitter;
        this[linkedin] = linkedin;
        this[customerID] = customerID;
        this[google_tag] = google_tag;
    }

    async updateSite(url) {
        let res = {"status": "success", "url": url}
        if(this.icon != null) {
            res = await imageUpload(this.icon, "web-icons");
        }
        
        if(res.status == "success") {
            await updateDoc(doc(db, 'sites', this.url), {
                name: this.name,
                icon: res.url,
                colors: this.colors,
                slogan: this.slogan,
            })
            return res.url;
        } else {
            // TODO - fail
        }
    }

    async createSite(uid) {
        console.log(uid)
        const docSnap = await getDoc(doc(db,'sites', this.name));
        if(!docSnap.exists()) {
            const res = await imageUpload(this.icon, "web-icons");
            if(res.status == "success") {
                try {
                    await setDoc(doc(db, 'sites', this.url), {
                        name: this.name,
                        icon: res.url,
                        colors: this.colors,
                        slogan: this.slogan,
                        orders: this.orders,
                        sessions: this.sessions,
                        revenue: this.revenue,
                        owner: uid,
                        link: this.url + ".sudoservices.io"
                    })
                } catch(e) {
                    console.log(e)
                }
                return this.name;
            } else {
                // TODO - fail
                console.log('here')
            }
        } else {
            // TODO - fail because same name
            console.log('here2')
        }
    }
}