import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "../components/CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51MnV7qFXzl8pcR54X3KCPBuiBN2q0IKp09WiOE89UegMlKq3YTVmZ5qzb3pYxE0bW7kM9eRJ9X8CHlhj6eWaur49005sdhTlVU");

export default function Checkout() {
  const [clientSecret, setClientSecret] = useState("");
  const cost = 1400;

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("https://us-central1-sunalabs.cloudfunctions.net/app/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ cost: cost }),
    })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
        {clientSecret && (
            <Elements options={options} stripe={stripePromise}>
                <CheckoutForm cost={cost} />
            </Elements>
        )}
    </>
  );
}