import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  XMarkIcon,
  TicketIcon,
  ChatBubbleLeftEllipsisIcon,
  ShoppingCartIcon
} from '@heroicons/react/24/outline'
import pic from "../images/sudoservices-transparent.png"
import { Link } from 'react-router-dom'
import { ArrowRightOnRectangleIcon} from '@heroicons/react/20/solid'
import { getAuth, signOut } from 'firebase/auth'
import { classNames } from '../consts'

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'Services', href: '/services', icon: FolderIcon },
  { name: 'Orders', href: '/orders', icon: ShoppingCartIcon },
  { name: 'Quotes', href: '/quotes', icon: TicketIcon },
  { name: 'Contacts', href: '/contacts', icon: ChatBubbleLeftEllipsisIcon },
]

function logout() {
  const auth = getAuth();
  signOut(auth).then(() => {
    console.log('Sign out successful');
  }).catch((error) => {
    console.log('Sign out failed');
  });
}

export default function MainLayout(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-white">
        <body class="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-8 w-auto"
                        src={pic}
                        alt="SudoServices"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    props.current == item.name
                                      ? 'bg-gray-50 text-green-600'
                                      : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      props.current == item.name ? 'text-green-600' : 'text-gray-400 group-hover:text-green-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto mb-5">
                          <ul>
                            <li className='mb-1'>
                              <Link
                                to="/settings"
                                className={classNames(
                                  props.current == "Settings"
                                    ? 'bg-gray-50 text-green-600'
                                    : 'text-gray-700 hover:text-green-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <Cog6ToothIcon
                                  className={classNames(
                                    props.current == "Settings" ? 'text-green-600' : 'text-gray-400 group-hover:text-green-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                Settings
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={logout}
                                className={classNames(
                                  'text-gray-700 hover:text-red-600 hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                )}
                              >
                                <ArrowRightOnRectangleIcon
                                  className={classNames(
                                    'text-gray-400 group-hover:text-red-600',
                                    'h-6 w-6 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                                Logout
                              </Link>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-cyan-800 px-6">
            <div className="flex flex-1 h-16 shrink-0 items-start">
              <img
                className="h-10 w-auto mt-5"
                src={pic}
                alt="SudoServices"
              />
            </div>
            <nav className="flex flex-col" style={{ "flex": "4" }}>
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            props.current == item.name
                              ? 'bg-cyan-700 text-white'
                              : 'text-cyan-200 hover:text-white hover:bg-cyan-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              props.current == item.name ? 'text-white' : 'text-cyan-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto mb-5">
                  <ul>
                    <li className='mb-1'>
                      <Link
                        to="/settings"
                        className={classNames(
                          props.current == "Settings"
                            ? 'bg-cyan-700 text-white'
                            : 'text-cyan-200 hover:text-white hover:bg-cyan-700',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <Cog6ToothIcon
                          className={classNames(
                            props.current == "Settings" ? 'text-white' : 'text-cyan-200 group-hover:text-white',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={logout}
                        className={classNames(
                          'text-cyan-200 hover:text-red-600 hover:bg-cyan-200',
                          'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                        )}
                      >
                        <ArrowRightOnRectangleIcon
                          className={classNames(
                            'text-cyan-200 group-hover:text-red-600',
                            'h-6 w-6 shrink-0'
                          )}
                          aria-hidden="true"
                        />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        <main className="py-10 lg:pl-72">
          <div className="px-4 sm:px-6 lg:px-8">
            {props.content}
          </div>
        </main>
      </div>
    </>
  )
}