import React, { useState } from "react";
import { Accordion, Button } from "react-bootstrap";
import FlowStep from "../components/FlowStep";
import Nav2 from "../components/Nav2";
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';
import "../styles/Create.css"
import { Link, Navigate } from "react-router-dom";
import { Package, Question, Service, Pricing, Gallery } from "../services/service_class";
import "../styles/Package-Form.css";
import values from "../values";
import MainLayout from "../layouts/MainLayout";
import { UserCircleIcon, PhotoIcon } from "@heroicons/react/24/outline";

class Create extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <MainLayout current="Services" content={<CreateContent />} />
        );
    }
}

const pages = [
    { name: 'Services', href: '/services', current: false },
    { name: 'Create Service', href: '/create', current: true },
]

class CreateContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = { steps: [], questions: [], count: 3, goodPackage: new Package('Good', '', 0, 0, [], [], 0, 'Purchase'), betterPackage: new Package('Better', '', 0, 0, [], [], 0, 'Purchase'), bestPackage: new Package('Best', '', 0, 0, [], [], 0, 'Purchase'), files: [], error: '', submitted: false, serviceID: '' };
        for (let i = 1; i < 3; i++) {
            this.state.steps.push(
                i
            )
            this.state.questions.push(
                new Question("", "", "")
            )
        }

        this.addStep = this.addStep.bind(this);
        this.removeStep = this.removeStep.bind(this);
        this.createService = this.createService.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.validate = this.validate.bind(this);
        this.setPackage = this.setPackage.bind(this);
        this.setFiles = this.setFiles.bind(this)
    }

    removeStep(index, index2) {
        const newArray = this.state.steps.filter(
            item => item != index
        );
        const newQuestions = [...this.state.questions]
        newQuestions.splice(index2, 1);
        if (newArray.length != 0) {
            this.setState({
                steps: newArray,
                questions: newQuestions
            })
        }
    }

    validate(name) {
        const element = document.getElementById(name);
        if (element.value == "") {
            const error = document.getElementById(name + "-feedback");
            error.style.display = "block";
        }
    }

    handleSelect(value, info) {
        if (value != "") {
            const error = document.getElementById(info.name + "-feedback");
            error.style.display = "none";
        }
        const id = info.name;
        this.setState({
            [id]: value
        });
    }

    addStep() {
        const id = this.state.count;
        this.setState({
            steps: [
                ...this.state.steps,
                id
            ],
            questions: [
                ...this.state.questions,
                new Question("", "", "")
            ]
        })
        this.setState({ count: this.state.count + 1 })
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    async createService(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            try {
                let service = new Service(values.site, e.target.elements.serv_name.value, new Pricing(this.state.goodPackage, this.state.betterPackage, this.state.bestPackage), e.target.elements.about.value, this.state.questions, new Gallery(this.state.files, []));
                service.createService();
                this.setState({
                    submitted: true,
                    serviceID: e.target.elements.serv_name.value
                })
            } catch (e) {
                this.setState({
                    error: e.message
                })
                setTimeout(
                    function () {
                        this.setState({
                            error: ''
                        })
                    }.bind(this), 5000);
            }
        }
        e.target.classList.add('was-validated');
    }

    setPackage(pkg, packageName) {
        this.setState({
            [packageName]: pkg
        })
    }

    setFiles(files) {
        this.setState({
            files: files
        })
    }

    newQuestion(newQuestion, c) {
        let questions = [...this.state.questions]
        questions[c] = new Question(newQuestion.question, newQuestion.response_type, newQuestion.required);

        this.setState({
            questions: questions
        });
    }

    render() {
        return (
            <>
                {
                    this.state.submitted && <Navigate to={'/services/' + this.state.serviceID} replace={true} />
                }
                <div className="Page">
                    <div className="Create bg-base-1">
                        <div class="container py-5 px-5">
                            <div class="row">
                                <div class="col-12 col-lg">
                                    <Nav2 pages={pages} />
                                </div>
                            </div>
                            <br />
                            <div className="md:flex md:items-center md:justify-between">
                                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                                    Create Service
                                </h2>
                            </div>
                            <br />
                            <form novalidate onSubmit={this.createService}>
                                <div className="space-y-12">
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Main Details</h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                These are the most important details of your service.
                                            </p>
                                        </div>

                                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                                            <div className="sm:col-span-4">
                                                <label htmlFor="website" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Name
                                                </label>
                                                <div className="mt-2">
                                                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md pl-3">
                                                        <input
                                                            type="text"
                                                            name="website"
                                                            id="serv_name"
                                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                            placeholder="Web Development"
                                                            style={{ "outline": "none" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-full">
                                                <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                                    About
                                                </label>
                                                <div className="mt-2">
                                                    <textarea
                                                        id="about"
                                                        name="about"
                                                        rows={3}
                                                        className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                        defaultValue={''}
                                                    />
                                                </div>
                                                <p className="mt-3 text-sm leading-6 text-gray-600">Write a lengthy service description.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Pricing</h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">You have to get paid for your services right? Let them know how much it costs and what is included in each package.</p>
                                        </div>

                                        <div className="gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                            <div className="package-form">
                                                <PackageForm
                                                    packageInstance={this.state.goodPackage}
                                                    onPackageChange={(updatedPackage) => this.setPackage(updatedPackage, "goodPackage")}
                                                />
                                                <PackageForm
                                                    packageInstance={this.state.betterPackage}
                                                    onPackageChange={(updatedPackage) => this.setPackage(updatedPackage, "betterPackage")}
                                                />
                                                <PackageForm
                                                    packageInstance={this.state.bestPackage}
                                                    onPackageChange={(updatedPackage) => this.setPackage(updatedPackage, "bestPackage")}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Requirements</h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                This is all the information you will need in order to do the best possible job.
                                            </p>
                                        </div>

                                        <div className="max-w-2xl space-y-10 md:col-span-2">
                                            <button
                                                type="button"
                                                className="rounded-md bg-green-50 px-2.5 py-1.5 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100"
                                                onClick={this.addStep}>Add Question</button>
                                            <br />
                                            {
                                                this.state.steps.map((c, i) => {
                                                    return <FlowStep key={c} step={i + 1} question={this.state.questions[i]} remove={() => { this.removeStep(c, i) }} onQuestionChange={(newQuestion) => { this.newQuestion(newQuestion, i) }} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                                        <div>
                                            <h2 className="text-base font-semibold leading-7 text-gray-900">Gallery</h2>
                                            <p className="mt-1 text-sm leading-6 text-gray-600">
                                                Display your talents with photos!
                                            </p>
                                        </div>

                                        <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">

                                            <div className="col-span-full">
                                                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Gallery Photos
                                                </label>
                                                <FileUpload getFiles={(files) => this.setFiles(files)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <Link to="/services">
                                        <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
                                            Cancel
                                        </button>
                                    </Link>
                                    <button
                                        type="submit"
                                        className="rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                                    >
                                        Create
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.error != "" ?
                    <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
                        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                            </svg>
                            <span class="sr-only">Error icon</span>
                        </div>
                        <div class="ms-3 text-sm font-normal">{this.state.error}</div>
                    </div> : <></> }
            </>
        )
    }
}


function FileUpload({ getFiles }) {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (e) => {
        const files = e.target.files;
        console.log(files)
        getFiles(files);
        setSelectedFiles(files)
    };

    const fileItems = [];
    for (var i = 0; i < selectedFiles.length; i++) {
        fileItems.push(
        <div key={i} className="file-item">
            <span className="mt-1 text-sm leading-6 text-gray-400">{selectedFiles[i].name}</span>
        </div>
        )
    }
    return (
        <>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-transparent font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500"
                            >
                                <span>Upload multiple files</span>
                                <input id="file-upload" name="file-upload" type="file" className="sr-only" multiple onChange={handleFileChange} />
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
                    </div>
                </div>
                <br />
                {selectedFiles.length != 0 && <div className="file-list">
                    <p className="block text-sm font-medium leading-6 text-gray-900">Uploading</p>
                    {fileItems}
                </div>}
        </>
    );
}

function PackageForm({ packageInstance, onPackageChange }) {
    const handleChange = (event) => {
        const { name, value } = event.target;
        const updatedPackage = { ...packageInstance, [name]: value };
        onPackageChange(packageInstance.from_json(updatedPackage));
    };

    return (
        <div>
            <div className="pb-20">
                <h2 className="text-base font-semibold leading-7 text-gray-900">{packageInstance.name} Package</h2>

                <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                        <label htmlFor={`${packageInstance.name}Name`} className="block text-sm font-medium leading-6 text-gray-900">
                            Name
                        </label>
                        <div className="mt-2">
                            <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600 sm:max-w-md pl-3">
                                <input
                                    style={{ "outline": "none" }}
                                    type="text"
                                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    placeholder="Good"
                                    id={`${packageInstance.name}Name`}
                                    name="name"
                                    value={packageInstance.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor={`${packageInstance.name}Details`} className="block text-sm font-medium leading-6 text-gray-900">
                            Details
                        </label>
                        <div className="mt-2">
                            <textarea
                                rows={3}
                                className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                defaultValue={''}
                                id={`${packageInstance.name}Details`}
                                name="details"
                                value={packageInstance.details}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor={`${packageInstance.name}Delivery`} className="block text-sm font-medium leading-6 text-gray-900">
                            Delivery
                        </label>
                        <div className="mt-2">
                            <div className=" focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600">
                                <input
                                    style={{ "outline": "none" }}
                                    autoComplete="delivery"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                                    placeholder="0"
                                    type="number"
                                    id={`${packageInstance.name}Delivery`}
                                    name="delivery"
                                    value={packageInstance.delivery}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-4">
                        <label htmlFor={`${packageInstance.name}Revisions`} className="block text-sm font-medium leading-6 text-gray-900">
                            Revisions
                        </label>
                        <div className="mt-2">
                            <div className=" focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600">
                                <input
                                    style={{ "outline": "none" }}
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                                    placeholder="0"
                                    type="number"
                                    id={`${packageInstance.name}Revisions`}
                                    name="revisions"
                                    value={packageInstance.revisions}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>


                    <div className="sm:col-span-4">
                        <label htmlFor={`${packageInstance.name}Price`} className="block text-sm font-medium leading-6 text-gray-900">
                            Price
                        </label>
                        <div className="mt-2">
                            <div className=" focus-within:ring-2 focus-within:ring-inset focus-within:ring-green-600">
                                <input
                                    style={{ "outline": "none" }}
                                    autoComplete="price"
                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                                    placeholder="0"
                                    type="number"
                                    id={`${packageInstance.name}Price`}
                                    name="price"
                                    value={packageInstance.price}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">Price will not show if action item is not "Purchase"</p>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor={`${packageInstance.name}ItemsIncluded`} className="block text-sm font-medium leading-6 text-gray-900">
                            Items Included
                        </label>
                        <div className="mt-2">
                            <textarea
                                style={{ "outline": "none" }}
                                placeholder="Separate items with new line"
                                rows={3}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-2"
                                defaultValue={''}
                                id={`${packageInstance.name}ItemsIncluded`}
                                name="items_included"
                                value={packageInstance.items_included}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor={`${packageInstance.name}ItemsNotIncluded`} className="block text-sm font-medium leading-6 text-gray-900">
                            Items Not Included
                        </label>
                        <div className="mt-2">
                            <textarea
                                style={{ "outline": "none" }}
                                placeholder="Separate items with new line"
                                rows={3}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 px-2"
                                defaultValue={''}
                                id={`${packageInstance.name}ItemsNotIncluded`}
                                name="items_not_included"
                                value={packageInstance.items_not_included}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="col-span-full">
                        <label htmlFor={`${packageInstance.name}Action`} className="block text-sm font-medium leading-6 text-gray-900">
                            Action Item
                        </label>
                        <div className="mt-2">
                            <select
                                name="action"
                                style={{ "outline": "none" }}
                                autoComplete="action-name"
                                id={`${packageInstance.name}Action`}
                                value={packageInstance.action}
                                onChange={handleChange}
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:max-w-xs sm:text-sm sm:leading-6"
                            >
                                <option value="Purchase">Purchase</option>
                                <option value="Quote">Request a Quote</option>
                                <option value="Contact">Contact</option>
                            </select>
                            <p className="text-xs leading-5 text-gray-600">Purchase - They purchase service based on package price. <br />Quote - Customer fills in requirements, and then you send them a price. <br />Contact - They fill in the contact us form.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Create;