import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { PhotoIcon, TruckIcon } from '@heroicons/react/24/outline'
import { fulfillOrder } from '../services/firebase_service'
import values from '../values'
import { imageUpload } from '../firebaseConfig'
import gif from "../loading.gif"
import { classNames } from '../consts'

const deliveryOptions = [
    { name: 'Electronic Delivery', inStock: true },
    { name: 'Physical Delivery', inStock: true },
]

export default function Fulfill(props) {
    const [mem, setMem] = useState(deliveryOptions[0])
    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    

    async function FulfillOrder(type, file) {
        setLoading(true)
        try {
            if(type == "Electronic Delivery") {
                if(file != null) {
                    await fulfillOrder(values.site, props.orderNo, type, await imageUpload(file, "delivery"), props.email)
                }
            } else if (type == "Physical Delivery") {
                await fulfillOrder(values.site, props.orderNo, type, null, props.email)
            }
            setLoading(false)
            props.close();
        } catch(e) {
            console.log(e)
            setLoading(false)
        }
    }

    return (
        <div>
            <div className="flex items-center justify-between">
                <h2 className="text-sm font-medium leading-6 text-gray-900">Fulfillment</h2>
            </div>

            <RadioGroup value={mem} onChange={setMem} className="mt-2">
                <RadioGroup.Label className="sr-only">Choose a memory option</RadioGroup.Label>
                <div className="grid grid-cols-2 gap-3 sm:grid-cols-2">
                    {deliveryOptions.map((option) => (
                        <RadioGroup.Option
                            key={option.name}
                            value={option}
                            className={({ active, checked }) =>
                                classNames(
                                    option.inStock ? 'cursor-pointer focus:outline-none' : 'cursor-not-allowed opacity-25',
                                    active ? 'ring-2 ring-green-600 ring-offset-2' : '',
                                    checked
                                        ? 'bg-green-600 text-white hover:bg-green-500'
                                        : 'ring-1 ring-inset ring-gray-300 bg-white text-gray-900 hover:bg-gray-50',
                                    'flex items-center justify-center rounded-md py-3 px-3 text-sm font-semibold uppercase sm:flex-1'
                                )
                            }
                            disabled={!option.inStock}
                        >
                            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                        </RadioGroup.Option>
                    ))}
                </div>
            </RadioGroup>
            { loading == false ?
            <>
            {mem.name == "Electronic Delivery" ?

                <div>
                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                        <div className="text-center">
                            {file == null && <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />}
                            {file != null && <p>{file.name}</p>}
                            <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                                <label
                                    htmlFor="file-upload"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-green-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-green-600 focus-within:ring-offset-2 hover:text-green-500"
                                >
                                    <span>Upload a file</span>
                                    <input id="file-upload" name="file-upload" type="file" className="sr-only" onChange={(e) => setFile(e.target.files[0])} />
                                </label>
                            </div>
                            {file == null && <p className="text-xs leading-5 text-gray-600">It should be one file. If it is a folder, please ZIP it.</p>}
                        </div>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">We will send the user an email with a link to the file.</p>
                </div>

                :

                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                    <div className="text-center">
                        <TruckIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                        <div className="mt-4 flex text-sm leading-6 text-gray-600 justify-center">
                            <p>You will have to deliver the item yourself.</p>
                        </div>
                    </div>
                </div>

            }
            </>
            
            :

            <div id="loading-content">
                <img src={gif} />
            </div>
            }

            <div className="mt-5 sm:mt-6">
                <button
                    type="button"
                    className={
                        classNames(
                            file == null && mem.name == "Electronic Delivery" ? "bg-gray-400 focus-visible:outline-gray-600" : "bg-green-600 hover:bg-green-500 focus-visible:outline-green-600",
                            "inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        )
                    }
                    onClick={() => {FulfillOrder(mem.name, file)}}
                >
                    Fulfill
                </button>
            </div>
        </div>
    )
}