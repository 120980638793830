import values from "../values";

export class Quote {
    constructor(id, site, service, name, email, responses, time, pkg, status, doc, quote) {
        this.id = id;
        this.site = site;
        this.service = service;
        this.name = name;
        this.email = email;
        this.responses = responses;
        this.time = time;
        this.pkg = pkg;
        this.status = status;
        this.doc = doc;
        this.quote = quote;
    }

    from_json(id, data, doc) {
        return new Quote(id, values.site, data.service, data.name, data.email, data.responses, data.time, data.package, data.status, doc, data.quote);
    }

    to_json() {
        return {
            id: this.id,
            site: this.site,
            service: this.service,
            name: this.name,
            email: this.email,
            responses: this.responses,
            time: this.time,
            pkg: this.pkg,
            status: this.status,
            quote: this.quote
        }
    }
}