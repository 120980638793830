import React, { useState } from 'react';
import '../styles/Login.css';
import {  signInWithEmailAndPassword } from 'firebase/auth';
import { Link } from 'react-router-dom';
import tooler from "../images/sudoservices-transparent.png"

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { email: '', password: '', cname: '', cemail: '', cpassword: '', sitename: '', siteurl: '', siteicon: '', sitecolor: '', siteslogan: '', error: '' };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFile = this.handleFile.bind(this);
    }

    handleChangeComplete = (color) => {
        this.setState({ sitecolor: color.hex });
    };

    handleLogin(e) {
        if (!e.target.checkValidity()) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            e.preventDefault();
            e.stopPropagation();
            signInWithEmailAndPassword(this.props.auth, this.state.email, this.state.password)
                .then((userCredential) => {
                    console.log("Successful Sign In");
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    this.setState({
                        error: errorMessage
                    })
                    setTimeout(
                        function () {
                            this.setState({
                                error: ''
                            })
                        }.bind(this), 5000);
                });
        }
        e.target.classList.add('was-validated');
    }

    handleChange(e) {
        const id = e.target.id;
        this.setState({
            [id]: e.target.value
        })
    }

    handleFile(e) {
        const id = e.target.id;
        const files = e.target.files[0];
        this.setState({
            [id]: files
        })
    }

    validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    nextStep(step) {
        if (this.validateEmail(this.state.cemail) && this.state.cname != '' && this.state.cpassword != '') {
            document.getElementById('step' + step).classList.remove('active');
            document.getElementById('step' + (step + 1)).classList.add('active');
        } else {
            document.getElementById("sign-up").classList.add('was-validated');
        }
    }

    prevStep(step) {
        document.getElementById('step' + step).classList.remove('active');
        document.getElementById('step' + (step - 1)).classList.add('active');
    }

    render() {
        return (
            <>
        <div className="flex min-h-full flex-1" style={{"min-height": "100vh"}}>
          <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img
                  className="h-10 w-auto"
                  src={tooler}
                  alt="SudoServices"
                />
                <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm leading-6 text-gray-500">
                  Not a member?{' '}
                  <Link to="/register" className="font-semibold text-green-600 hover:text-green-500">
                    Start a 14 day free trial
                  </Link>
                </p>
              </div>
  
              <div className="mt-10">
                <div>
                  <form className="space-y-6" onSubmit={this.handleLogin}>
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required
                          value={this.state.email}
                          onChange={this.handleChange}
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                        />
                      </div>
                    </div>
  
                    <div>
                      <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                        Password
                      </label>
                      <div className="mt-2">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          autoComplete="current-password"
                          required
                          value={this.state.password}
                          onChange={this.handleChange}
                          className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6 pl-3"
                        />
                      </div>
                    </div>
  
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-600"
                        />
                        <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-700">
                          Remember me
                        </label>
                      </div>
  
                      <div className="text-sm leading-6">
                        <Link to="/forgotpassword" className="font-semibold text-green-600 hover:text-green-500">
                          Forgot password?
                        </Link>
                      </div>
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md bg-green-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      >
                        Sign in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="relative hidden w-0 flex-1 lg:block bg-mesher-gradient ml-20">
          </div>
        </div>
        {this.state.error != "" ?
        <div id="toast-danger" class="flex items-center w-full max-w-xs p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2" role="alert">
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                </svg>
                <span class="sr-only">Error icon</span>
            </div>
            <div class="ms-3 text-sm font-normal">{this.state.error}</div>
        </div> : <></> }
      </>
        );
    }
}

export default Login;